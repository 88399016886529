import {create} from 'zustand';
import {createJSONStorage, persist} from 'zustand/middleware';

import {Degree, UniversityData} from '@/models/Universities';

type UniversityStore = {
  identifier: number;
  name: string;
  publisher: UniversityData['publisher'] | null;
  degrees: UniversityData['degrees'];
  selectedDegree: Degree | null;
  studentId: string;
  setUniversity: (state: UniversityData) => void;
  setSelectedDegree: (degree: Degree) => void;
  setStudentId: (studentId: string) => void;
  isFulfill: () => boolean;
  initUniversity: () => void;
};

export const useUniversity = create<UniversityStore>()(
  persist(
    (set, get) => ({
      identifier: 0,
      name: '',
      publisher: null,
      degrees: [],
      selectedDegree: null,
      studentId: '',
      setUniversity: (state: UniversityData) => {
        set((prev) => ({...prev, selectedDegree: null, studentId: '', ...state}));
      },
      setSelectedDegree: (degree: Degree) => {
        set((prev) => ({...prev, selectedDegree: degree}));
      },
      setStudentId: (studentId: string) => {
        set((prev) => ({...prev, studentId}));
      },
      isFulfill: () => {
        const {name, selectedDegree, studentId} = get();
        return !!name && !!selectedDegree && !!studentId;
      },
      initUniversity: () => {
        set((prev) => ({
          ...prev,
          identifier: 0,
          name: '',
          publisher: null,
          degrees: [],
          selectedDegree: null,
          studentId: ''
        }));
      }
    }),
    {
      name: 'university',
      storage: createJSONStorage(() => localStorage)
    }
  )
);
