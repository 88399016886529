import {createRoot} from 'react-dom/client';

import '@/resources/style.css';
import '@/resources/custom.css';

import App from './App';

// 전자증명서와 동일하게 body{height: 100vh;}를 적용하기 위해 추가된 클래스
// height: 100vh;가 없을 경우 iOS에서 입력창 클릭시 키보드 위 '확인' 버튼의 위치가 어긋남
document.body.classList.add('layout');

const app = document.getElementById('app');
if (app) {
  const root = createRoot(app);
  root.render(<App />);
}
