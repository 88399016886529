import {SERVICE} from '@zzng/common/constants/service';

import MainContent from '@/components/MainContent';

const InvalidDevice = () => {
  return (
    <MainContent className="content-full">
      <div className="inner_g">
        <div className="group_empty">
          <span className="emoticon type1"></span>
          <strong className="tit_g">
            <span className="item_txt" role="text">
              <span className="txt_tit">
                주 사용 기기에 있는
                <br /> 카카오톡으로 이용해 주세요
              </span>
            </span>
          </strong>
          <p className="txt_g">
            {SERVICE.STUDENTID} 서비스는 주 사용 기기에서만 이용 가능합니다.
            <br /> (휴대폰, 태플릿, PC 등)
          </p>
        </div>
      </div>
    </MainContent>
  );
};

export default InvalidDevice;
