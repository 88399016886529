import {useEffect, useLayoutEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {SERVICE} from '@zzng/common/constants/service';
import {openExternalWebview} from '@zzng/common/scheme/web';

import Halfview from '@/components/Halfview';
import Space from '@/components/Space';
import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import {KAKAO_PRIVACY_POLICY} from '@/constants/urlLink';
import {useHistoryBackAOS} from '@/hooks/useHistoryBackAOS';
import useTiara from '@/hooks/useTiara';
import {useHalfviewStore} from '@/store/halfview';
import {useUniversity} from '@/store/university';

const KakaoTerms = () => {
  const {trackPage, trackEvent} = useTiara({pageName: TIARA_PAGE_NAME.ISSUE_KAKAO_TERMS});
  const navigate = useNavigate();
  const {setHalfviewClassname} = useHalfviewStore();
  const {publisher} = useUniversity();

  const handleBackEvent = () => {
    trackEvent({action: TIARA_ACTION.BACK_CLICK});
    navigate(-1);
  };

  const onClickPrivacyPolicy = () => {
    openExternalWebview(KAKAO_PRIVACY_POLICY, {
      isCurrentWebviewClose: false
    });
  };

  useHistoryBackAOS(handleBackEvent);

  useLayoutEffect(() => {
    setHalfviewClassname('modal_full modal_policy');
  }, []);

  useEffect(() => {
    trackPage({action: TIARA_ACTION.ISSUE_KAKAO_TERMS_VIEW});
  }, []);

  return (
    <>
      <Halfview.Header className="header_type2" onClickPrev={handleBackEvent} />
      <Halfview.Body>
        <div className="inner_g">
          <h1 className="tit_g type4">
            <span className="item_txt" role="text">
              <span className="txt_tit">카카오 개인정보 제3자 제공 동의</span>
            </span>
          </h1>
          <p className="txt_g type2">
            (주)카카오는 아래 목적으로 개인정보를 제3자에게 제공하며, 회원들의 개인정보를 안전하게 취급하는데 최선을
            다합니다.
          </p>
          <h2 className="tit_g type6">
            <span className="item_txt" role="text">
              <span className="txt_tit">[필수] 개인정보 제3자 제공동의</span>
            </span>
          </h2>
          <div className="group_tbl">
            <table className="tbl_g tbl_g2">
              <caption className="ir_caption">[필수] 개인정보 제3자 제공동의</caption>
              <colgroup>
                <col style={{width: '25%'}} />
                <col style={{width: '25%'}} />
                <col style={{width: '25%'}} />
                <col style={{width: '25%'}} />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">제공받는자</th>
                  <th scope="col">목적</th>
                  <th scope="col">항목</th>
                  <th scope="col">보유기간</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <em className="emph_text">{publisher?.name}</em>
                  </td>
                  <td>
                    <em className="emph_text">학생정보 확인용 증명서 발급</em>
                  </td>
                  <td>이름, 생년월일, 학교, 학위, 학번</td>
                  <td>
                    <em className="emph_text">증명서 발급시 지체없이 파기</em>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="group_list type_policy">
              <ul className="list_g type3" role="list">
                <li role="listitem">
                  <div className="item_list" role="text">
                    <span className="dot_item"></span>
                    <div className="cont_item">
                      제공받는 자의 정보는 발급된 {SERVICE.STUDENTID}에서 확인할 수 있습니다
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <p className="txt_g type2">위 동의를 거부할 권리가 있으며, 동의를 거부하실 경우 서비스 이용이 제한됩니다.</p>
          <p className="txt_g type2">
            더 자세한 내용에 대해서는
            <Space />
            <a href="#none" onClick={onClickPrivacyPolicy} className="txt_blue">
              카카오 개인정보처리방침
            </a>
            을 참고하 시기 바랍니다.
          </p>
        </div>
      </Halfview.Body>
    </>
  );
};

export default KakaoTerms;
