import {PostTermsRes} from '@/models/issue';
import {api} from '@/utils/api';

interface GetSignAndPostTermsProps {
  txId: string | null;
  publisherId: string | undefined;
  channelPlus: boolean;
  isMarketingUsageAgreed: boolean;
}

export const getSignAndPostTerms = async ({
  txId,
  publisherId,
  channelPlus,
  isMarketingUsageAgreed
}: GetSignAndPostTermsProps) => {
  await api('get', `/api/sign?txId=${txId}`);

  const {
    data: {name, birthdate}
  } = await api<PostTermsRes>('post', `/api/terms`, {
    publisherId,
    channelPlus,
    isMarketingUsageAgreed
  });
  return {name, birthdate};
};
