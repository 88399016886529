import {MouseEvent} from 'react';
import {useNavigate} from 'react-router-dom';

import {openInappbrowser} from '@zzng/common/scheme/inappbrowser';

import Space from '@/components/Space';
import {ROUTES} from '@/constants/routes';
import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import {CS_CENTER, PROPOSITION} from '@/constants/urlLink';
import useTiara from '@/hooks/useTiara';

const TxtDot = () => <span className="txt_dot">·</span>;

const WrapNotice = () => {
  const {trackEvent} = useTiara({pageName: TIARA_PAGE_NAME.SERVICE_INTRO});
  const navigate = useNavigate();

  const openExternal = (url: string) => {
    openInappbrowser(url);
  };

  const onAvailableUniversityListClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    trackEvent({action: TIARA_ACTION.AVAILABLE_UNIVERTSITY_CLICK});
    navigate(ROUTES.UNIVERSITY_LIST);
  };

  const onPropositionClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    trackEvent({action: TIARA_ACTION.PROPOSITION_CLICK});
    openExternal(PROPOSITION);
  };

  const onCsClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    trackEvent({action: TIARA_ACTION.CS_CLICK});
    openExternal(CS_CENTER);
  };

  const LIST_NOTICE = [
    <>
      카카오 톡학생증은 증명서를 통한 카드 발급 서비스로, 제휴된 증명서 발급 대행기관에서 제공하는 학교만 발급
      가능합니다.&nbsp;
      <Space />
      <a href="#none" className="link_notice" rel="noreferrer" onClick={onAvailableUniversityListClick}>
        발급학교보기
      </a>
    </>,
    <>
      제휴된 증명서 발급 대행기관에서 제공하는 학교더라도 학교와 발급 대행기관의 상황에 따라 서비스 학교에서 제외될 수
      있습니다(기발급된 톡학생증의 회수 및 효력중지 포함).&nbsp;
    </>,
    <>서비스 이용을 위해 카카오 인증서 발급이 필요하며, 인증서는 만 14세 이상만 발급이 가능합니다.&nbsp;</>,
    <>
      톡학생증 발급은 하나의 카카오 계정에서만 가능하며, 다른 계정에서 카드 발급을 시도한 경우 이미 발급되어 있는 카드는
      자동 회수됩니다.&nbsp;
    </>,
    <>
      재학 상태의 카드는 하나만 발급할 수 있으며, 또 다른 재학 상태 카드 발급을 시도한 경우 이전에 발급되어있는 재학
      상태의 카드는 자동 회수됩니다.&nbsp;
    </>,
    <>재학 상태의 카드는 발급일로부터 2년까지 유효합니다.&nbsp;</>,
    <>동일한 카드 발급을 시도한 경우, 이전에 발급한 카드는 자동 회수되고 재발급 됩니다.&nbsp;</>,
    <>
      학사정보가 변경된 경우, 재발급하여 정보를 수정할 수 있으며, 잘못 등록된 학사정보에 대한 문의는 학사정보 취급기관
      또는 각 대학으로 문의 바랍니다. &nbsp;
    </>,
    <>국내 카카오톡 사용자만 이모티콘을 받을 수 있습니다.&nbsp;</>,
    <>지급받은 이모티콘은 톡학생증 발급 후 톡디지털카드 채널을 통해 발송됩니다.&nbsp;</>,
    <>톡학생증 전용 이모티콘은 다운로드 받은 일로부터 1년간 사용 가능합니다.&nbsp;</>,
    <>서비스를 부정한 목적으로 이용할 경우 서비스 이용이 제한됩니다.&nbsp;</>,
    <>본 서비스는 카카오 또는 제휴사의 사정에 의해 사전 고지 없이 변경되거나 종료될 수 있습니다.&nbsp;</>,
    <>
      톡학생증에 대해 궁금하신 내용은 카카오 고객센터를 통해 확인할 수 있습니다.&nbsp;
      <Space />
      <a href="#none" className="link_notice" rel="noreferrer" onClick={onCsClick}>
        고객센터
      </a>
    </>,
    <>
      톡학생증과 함께할 수 있는 다양한 제안을 기다립니다.&nbsp;
      <Space />
      <a href="#none" className="link_notice" rel="noreferrer" onClick={onPropositionClick}>
        제휴제안
      </a>
    </>
  ];

  return (
    <div className="wrap_notice">
      <div className="inner_wrap">
        <h2>
          <span role="text" className="ico_intro ico_notice">
            안내드립니다!
          </span>
        </h2>
        <ul className="list_notice">
          {LIST_NOTICE.map((element, i) => (
            <li className="item_notice" key={i}>
              <TxtDot />
              {element}
            </li>
          ))}
        </ul>
        <small className="txt_copy">© Kakao Corp. All rights reserved.</small>
      </div>
    </div>
  );
};

export default WrapNotice;
