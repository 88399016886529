import MainContent from '@/components/MainContent';

const ServerError = () => {
  return (
    <MainContent className="content-full">
      <div className="inner_g">
        <div className="group_empty">
          <span className="emoticon type7"></span>
          <strong className="tit_g">
            <span className="item_txt" role="text">
              <span className="txt_tit">서비스 오류 안내</span>
            </span>
          </strong>
          <p className="txt_g">
            예상하지 못한 오류가 발생했습니다.
            <br />
            서버의 일시적인 장애이거나 네트워크 문제일 수 있습니다.
            <br />
            잠시 후 다시 시도해 주세요.
          </p>
        </div>
      </div>
    </MainContent>
  );
};

export default ServerError;
