import {create} from 'zustand';
import {createJSONStorage, persist} from 'zustand/middleware';

interface AgreementStatus {
  requiredKakaoCollectUse: boolean;
  requiredPersonalInfo: boolean;
  requiredPublisher: boolean;
}
interface OptionalAgreementStatus {
  isChannelPlus: boolean;
  isMarketingUsage: boolean;
}
interface NeedToShow {
  needToShowChannel: boolean;
  needToShowMarketing: boolean;
}

type AgreementStatusStore = {
  needToShow: NeedToShow;
  optionalAgreementStatus: OptionalAgreementStatus;
  requiredAgreementStatus: AgreementStatus;
  setNeedToShow: (needToShow: NeedToShow) => void;
  setOptionalAgreementStatus: (optionalAgreementStatus: OptionalAgreementStatus) => void;
  setRequiredAgreementStatus: (requiredAgreementStatus: AgreementStatus) => void;
  initAgreementStatus: () => void;
};

export const useAgreementStatusStore = create<AgreementStatusStore>()(
  persist(
    (set) => ({
      needToShow: {
        needToShowChannel: false,
        needToShowMarketing: false
      },
      optionalAgreementStatus: {
        isChannelPlus: false,
        isMarketingUsage: false
      },
      requiredAgreementStatus: {
        requiredKakaoCollectUse: false,
        requiredPersonalInfo: false,
        requiredPublisher: false
      },
      setNeedToShow: (needToShow) => {
        set((prev) => ({...prev, needToShow}));
      },
      setOptionalAgreementStatus: (optionalAgreementStatus) => {
        set((prev) => ({...prev, optionalAgreementStatus}));
      },
      setRequiredAgreementStatus: (requiredAgreementStatus) => {
        set((prev) => ({...prev, requiredAgreementStatus}));
      },
      initAgreementStatus: () => {
        set((prev) => ({
          ...prev,
          optionalAgreementStatus: {
            isChannelPlus: false,
            isMarketingUsage: false
          },
          requiredAgreementStatus: {
            requiredKakaoCollectUse: false,
            requiredPersonalInfo: false,
            requiredPublisher: false
          }
        }));
      }
    }),
    {
      name: 'studentid_agreement',
      storage: createJSONStorage(() => localStorage),
      // NOTE: 동의는 서명 이전에, 동의 API(POST /terms)는 서명 이후에 리다이렉트된 브릿지 페이지에서 날리기 떄문에 localStorage에 저장
      partialize: (state) => ({
        optionalAgreementStatus: state.optionalAgreementStatus
      })
    }
  )
);
