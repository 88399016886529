import {ServerErrorCode} from '@/constants/serverErrorCode';
import {api} from '@/utils/api';

const Button = ({onClick, text}: {onClick: () => void; text: string}) => {
  return (
    <button
      style={{
        width: '120px',
        height: '60px',
        backgroundColor: '#e7e7e7',
        color: 'black'
      }}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

type TestErrorInfo = {
  name: string;
  code: ServerErrorCode | 'server' | 'expired-pilsner-auth';
}[];

const ErrorList: TestErrorInfo = [
  {name: '서버 에러', code: 'server'},
  {name: '필스너 인증 만료 에러', code: 'expired-pilsner-auth'},
  {name: '발급 초과 혹은 제한 에러', code: ServerErrorCode.ABUSED_USER},
  {name: '본인 인증 에러', code: ServerErrorCode.INVALID_AUTH},
  {name: '발급 불가 학적 에러', code: ServerErrorCode.INVALID_CREDENTIAL_CATEGORY},
  {name: '서브 디바이스 에러', code: ServerErrorCode.INVALID_DEVICE},
  {name: '계정 없음 에러', code: ServerErrorCode.INVALID_LOGIN_STATUS},
  {name: '해외 사용자 에러', code: ServerErrorCode.INVALID_PHONE_NUMBER},
  {name: '학적 발급 에러', code: ServerErrorCode.INVALID_STUDENT_CREDENTIAL},
  {name: '학번 에러', code: ServerErrorCode.INVALID_STUDENT_IDENTIFIER},
  {name: '잘못된 학교 에러', code: ServerErrorCode.INVALID_UNIVERSITY_DEGREE},
  {name: '인증 혹은 서명 에러', code: ServerErrorCode.SIGN_FAILED},
  {name: '서비스 점검 에러', code: ServerErrorCode.UNDER_MAINTENANCE},
  {name: '학교 중단 시 에러', code: ServerErrorCode.UNDER_MAINTENANCE_UNIVERSITY},
  {name: '디지털 카드 발급 실패 에러', code: ServerErrorCode.DIGITAL_CARD_ERROR},
  {name: '발행처 시스템별 circuit open 에러', code: ServerErrorCode.CIRCUIT_OPEN},
  {name: '동시 발급 제한 안내 에러', code: ServerErrorCode.EXCESSIVE_REQUESTS}
];

const ErrorTest = () => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        margin: '10px',
        gap: '10px'
      }}
    >
      {ErrorList.map((error, idx) => (
        <Button
          key={idx}
          onClick={async () => {
            await api('get', `/api/error/${error.code}`);
          }}
          text={error.name}
        />
      ))}
    </div>
  );
};

export default ErrorTest;
