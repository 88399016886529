import {useLocation} from 'react-router-dom';

import {checkOnline} from '@zzng/common/utils/common';

import MainContent from '@/components/MainContent';
import {ROUTES} from '@/constants/routes';
import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import useDarkMode from '@/hooks/useDarkMode';
import useTiara from '@/hooks/useTiara';

const Refresh = ({isDarkMode}: {isDarkMode: boolean}) => (
  <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.5 37C28.7173 37 37 28.7173 37 18.5C37 8.28273 28.7173 0 18.5 0C8.28273 0 0 8.28273 0 18.5C0 28.7173 8.28273 37 18.5 37Z"
      fill={isDarkMode ? 'white' : 'black'}
      fillOpacity={isDarkMode ? '0.1' : '0.04'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.6667 16H28.5V10.1667H27.6667V14.5C26.1225 10.9692 22.5975 8.5 18.5 8.5C12.9808 8.5 8.5 12.9808 8.5 18.5C8.5 24.0192 12.9808 28.5 18.5 28.5C23.7383 28.5 28.0417 24.4633 28.4658 19.3333H27.6292C27.2075 24.0025 23.2783 27.6667 18.5 27.6667C13.4408 27.6667 9.33333 23.5592 9.33333 18.5C9.33333 13.4408 13.4408 9.33333 18.5 9.33333C22.3833 9.33333 25.7058 11.7533 27.0408 15.1667H22.6667V16Z"
      fill={isDarkMode ? '#F2F2F2' : 'black'}
    />
  </svg>
);

type NetworErrorState = {
  state: {
    returnUrl?: string;
  };
};

const NetworkError = () => {
  const {trackEvent} = useTiara({pageName: TIARA_PAGE_NAME.ERROR});
  const {state} = useLocation() as NetworErrorState;
  const {isDarkMode} = useDarkMode();

  const onClickRetry = async () => {
    trackEvent({action: TIARA_ACTION.NETWORK_RELOAD_CLICK});

    if (!(await checkOnline())) return;

    const replaceUrl = state?.returnUrl ? state.returnUrl : ROUTES.SERVER_ERROR;
    location.replace(replaceUrl);
  };

  return (
    <MainContent className="content-full">
      <div className="inner_g">
        <div className="group_empty">
          <p className="txt_g type2">
            네트워크 연결 상태가 좋지 않습니다.
            <br /> 잠시 후 다시 시도해 주세요.
          </p>
          <button type="button" className="btn_refresh" onClick={onClickRetry}>
            <Refresh isDarkMode={isDarkMode} />
          </button>
        </div>
      </div>
    </MainContent>
  );
};

export default NetworkError;
