import {create} from 'zustand';
import {createJSONStorage, persist} from 'zustand/middleware';

type UserStore = {
  name: string;
  birthDate: number;
  setName: (name: string) => void;
  setBirthDate: (birthDate: number) => void;
};

export const useUserStore = create<UserStore>()(
  persist(
    (set) => ({
      name: '',
      birthDate: 0,
      setName: (name) => {
        set((prev) => ({...prev, name}));
      },
      setBirthDate: (birthDate) => {
        set((prev) => ({...prev, birthDate}));
      }
    }),
    {
      name: 'user',
      storage: createJSONStorage(() => localStorage)
    }
  )
);
