import {ChangeEvent, useEffect, useRef, useState} from 'react';
import {Outlet, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import cn from 'classnames';

import {SERVICE} from '@zzng/common/constants/service';
import {addPaddingBtmOnFixedBtm} from '@zzng/common/markup/addPaddingBtmOnFixedBtm';
import {transitionEmphline} from '@zzng/common/markup/transitionEmphline';
import {closeWebView} from '@zzng/common/scheme/web';

import Header from '@/components/Header';
import Input from '@/components/Input';
import Layout from '@/components/Layout';
import MainContent from '@/components/MainContent';
import Select, {SelectComponent} from '@/components/Select';
import Space from '@/components/Space';
import {HeaderDescription} from '@/constants/headerDescription';
import {ROUTES} from '@/constants/routes';
import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import {useHistoryBackAOS} from '@/hooks/useHistoryBackAOS';
import useTiara from '@/hooks/useTiara';
import {GetTermsRes} from '@/models/issue';
import {useAgreementStatusStore} from '@/store/agreementStatus';
import {usePresentStore} from '@/store/presentStore';
import {useUniversity} from '@/store/university';
import {api} from '@/utils/api';

export type IssueOutletContext = {
  /** 학교 선택 시 */
  onExitedWithUniversitySelected: () => void;
  /** 학위 선택 시 */
  onExitedWithDegreeSelected: () => void;
};

const Issue = () => {
  const {trackEvent, trackPage} = useTiara({pageName: TIARA_PAGE_NAME.ISSUE});

  const navigate = useNavigate();
  const location = useLocation();

  const lineElement = useRef<HTMLSpanElement>(null);
  const universityRef = useRef<SelectComponent>();
  const degreeRef = useRef<SelectComponent>();
  const studentIdInputRef = useRef<HTMLInputElement>(null);

  const {name, degrees, selectedDegree, studentId, setStudentId, isFulfill} = useUniversity();
  const studentIdErrorMessage = /[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(studentId) ? '한글은 입력할 수 없습니다.' : '';
  const submitButtonDisabled = !isFulfill() || !!studentIdErrorMessage;
  const {setNeedToShow} = useAgreementStatusStore();
  const {setPTxId} = usePresentStore();

  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();

  const pTxId = searchParams.get('presentationTxId') || ''; // 제출하기에서 진입시 넘어오는 값

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStudentId(e.target.value);
  };

  const onClickClear = () => {
    setStudentId('');
  };

  const onUniversityClick = () => {
    trackEvent({action: TIARA_ACTION.UNIVERSITY_SELECTION_CLICK});

    navigate(ROUTES.ISSUE_UNIVERSITY_SEARCH);
  };

  const onDegreeClick = () => {
    trackEvent({action: TIARA_ACTION.DGREE_SELECTION_CLICK});

    navigate(ROUTES.ISSUE_DEGREE);
  };

  const onClickClose = () => {
    trackEvent({action: TIARA_ACTION.CLOSE_CLICK});
    closeWebView();
  };

  const onNextClick = () => {
    trackEvent({action: TIARA_ACTION.NEXT_CLICK});

    navigate(ROUTES.ISSUE_AGREEMENT);
  };

  const issueOutletContext: IssueOutletContext = {
    onExitedWithUniversitySelected: () => {
      navigate(ROUTES.ISSUE_DEGREE);
    },
    onExitedWithDegreeSelected: () => {
      studentIdInputRef.current?.focus();
    }
  };

  useEffect(() => {
    setPTxId(pTxId);
  }, []);

  useEffect(addPaddingBtmOnFixedBtm, []);
  useEffect(transitionEmphline, [isLoading]);

  useEffect(() => {
    trackPage({action: TIARA_ACTION.ISSUE_VIEW});

    async function getTerms() {
      const {
        data: {needToShowChannel, needToShowMarketing}
      } = await api<GetTermsRes>('get', '/api/terms');
      setNeedToShow({needToShowChannel, needToShowMarketing});
      setIsLoading(false);
    }
    getTerms();
  }, []);

  useEffect(() => {
    if (!universityRef.current || !degreeRef.current) return;

    universityRef.current.focusOut();
    degreeRef.current.focusOut();

    if (location.pathname === ROUTES.ISSUE_UNIVERSITY_SEARCH) {
      universityRef.current.focus();
    }

    if (location.pathname === ROUTES.ISSUE_DEGREE) {
      degreeRef.current.focus();
    }
  }, [location]);

  useHistoryBackAOS(() => {
    onClickClose();
  });

  return (
    <>
      <Layout className="container-apply">
        <Header title={HeaderDescription.DEFAULT} onClickClose={onClickClose} />
        {!isLoading && (
          <MainContent>
            <div className="inner_g">
              <div className="group_tit">
                <h2 className="tit_g">
                  <span className="item_txt item_emph" role="text">
                    <span className="txt_tit">반갑습니다!</span>
                    <span className="txt_tit">
                      <em className="emph_color">
                        {SERVICE.STUDENTID} 발급
                        <span className="emph_line" ref={lineElement}></span>
                      </em>
                      을
                    </span>
                    <Space />
                    <span className="txt_tit">진행할게요.</span>
                  </span>
                </h2>
              </div>
              <div className="group_item">
                <h3 className="item_tit">학교</h3>
                <Select
                  ref={universityRef}
                  onClick={onUniversityClick}
                  placeholder="학교 이름을 검색해 주세요."
                  selectedValue={name}
                />
              </div>
              {degrees.length > 0 && (
                <div className="group_item">
                  <h3 className="item_tit">학위</h3>
                  <Select
                    ref={degreeRef}
                    onClick={onDegreeClick}
                    placeholder="학위를 선택해 주세요."
                    selectedValue={selectedDegree?.name}
                  />
                </div>
              )}
              {!!name && (
                <>
                  <div className="group_item">
                    <h3 className="item_tit">학번</h3>
                    <Input
                      ref={studentIdInputRef}
                      input={studentId}
                      type="text"
                      htmlFor="studentIdInp"
                      onChange={onChange}
                      onClickClear={onClickClear}
                      label="학번 검색"
                      placeholder="예) 20234547, A230527"
                      errorMessage={studentIdErrorMessage}
                      showFixedConfirmBtm
                    />
                  </div>
                  <div className="group_box type2">
                    <div className="group_list type_dot type5">
                      <strong className="tit_box">학번 입력시 주의사항</strong>
                      <ul className="list_g" role="list">
                        <li role="listitem">
                          <div className="item_list" role="text">
                            <span className="dot_item" />
                            <div className="cont_item">입학년도가 아닌 본인의 고유학번을 입력해 주세요.</div>
                          </div>
                        </li>
                        <li role="listitem">
                          <div className="item_list" role="text">
                            <span className="dot_item" />
                            <div className="cont_item">영문을 포함하는 경우 대/소문자를 정확히 입력해 주세요.</div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="fixed_btm">
              <div className="group_btn">
                <button
                  type="button"
                  className={cn('btn btn_yellow', {disabled: submitButtonDisabled})}
                  disabled={submitButtonDisabled}
                  onClick={onNextClick}
                >
                  다음
                </button>
              </div>
            </div>
          </MainContent>
        )}
      </Layout>
      <Outlet context={issueOutletContext} />
    </>
  );
};

export default Issue;
