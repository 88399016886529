import {MouseEventHandler} from 'react';

import {openDigitalCard} from '@zzng/common/scheme/digitalcard';
import {isEmbedOrigin} from '@zzng/common/utils/isEmbedOrigin';

import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import {DIGITAL_CARD_EVENT_BENEFIT} from '@/constants/urlLink';
import useTiara from '@/hooks/useTiara';

const WrapBenefit = () => {
  const {trackEvent} = useTiara({pageName: TIARA_PAGE_NAME.SERVICE_INTRO});

  const onClickMore: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    trackEvent({action: TIARA_ACTION.MORE_BENEFIT_CLICK});

    if (isEmbedOrigin()) {
      openDigitalCard(DIGITAL_CARD_EVENT_BENEFIT);
      return;
    }

    window.open(DIGITAL_CARD_EVENT_BENEFIT, '_blank');
  };

  return (
    <div className="wrap_benefit">
      <div className="inner_wrap">
        <div className="box_top">
          <h3 className="tit_top">
            <span className="ico_intro ico_emph2">발급 혜택</span>
          </h3>
          <span className="ico_intro ico_tit2" role="text">
            톡학생증으로 만나는 <strong>특별한 혜택</strong>
          </span>
        </div>
        <ul className="list_benefit">
          <li className="item_benefit">
            <span className="ico_intro ico_benefit1" />
            <div className="box_txt">
              <span className="ico_intro ico_txt1">생활용품 10% 할인 쿠폰 - 다이소</span>
            </div>
          </li>
          <li className="item_benefit">
            <span className="ico_intro ico_benefit2" />
            <div className="box_txt">
              <span className="ico_intro ico_txt2">패스트푸드 30% 할인 쿠폰 - 맥도날드, 버거킹</span>
            </div>
          </li>
          <li className="item_benefit">
            <span className="ico_intro ico_benefit3" />
            <div className="box_txt">
              <span className="ico_intro ico_txt3">편의점 10% 할인쿠폰 - 세븐일레븐, 이마트 24</span>
            </div>
          </li>
          <li className="item_benefit">
            <span className="ico_intro ico_benefit4" />
            <div className="box_txt">
              <span className="ico_intro ico_txt4">카페 15% 할인쿠폰 - 더벤티, 공차</span>
            </div>
          </li>
        </ul>
        <div className="box_txt">
          <span className="ico_intro ico_info2" role="text">
            1천 원 이상 카카오페이머니 결제 시 최대 1천 원 할인
          </span>
        </div>
        <div className="group_btn">
          <a href="#none" className="btn_intro" onClick={onClickMore}>
            <span className="ico_intro ico_btn2">추가되는 혜택 보러 가기</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default WrapBenefit;
