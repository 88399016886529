import {PostIssueRes} from '@/models/issue';
import {api} from '@/utils/api';

interface PostIssueProps {
  publisherId?: string;
  universityIdentifier: number;
  degreeIdentifier?: string;
  studentIdentifier: string;
  presentationTxId?: string;
}

export const postIssue = async ({
  publisherId,
  universityIdentifier,
  degreeIdentifier,
  studentIdentifier,
  presentationTxId
}: PostIssueProps) => {
  const {
    data: {presentationUrl, cardId}
  } = await api<PostIssueRes>('post', '/api/student-credential/issue', {
    publisherId,
    universityIdentifier,
    degreeIdentifier,
    studentIdentifier,
    presentationTxId
  });
  return {presentationUrl, cardId};
};
