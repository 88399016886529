const WrapUsage = () => (
  <div className="wrap_usage">
    <div className="inner_wrap">
      <div className="box_top">
        <h3 className="tit_top">
          <span className="ico_intro ico_emph3">사용설명서</span>
        </h3>
        <span className="ico_intro ico_tit3" role="text">
          어떻게 사용하는지 <strong>핵심만</strong> 알려드릴게요!
        </span>
      </div>
      <ul>
        <li className="item_usage">
          <div className="box_txt">
            <em className="ico_intro ico_subtit1">톡학생증 찾기</em>
            <span className="ico_intro ico_usage1" role="text">
              카카오톡 지갑에서 <br />
              신분/증명 탭을 확인하세요
            </span>
          </div>
          <div className="wrap_animation">
            <img
              src="https://t1.kakaocdn.net/zzng_static/images/20240709_134302/idcard/intro/intro_benefit1_240627.gif"
              alt=""
              className="visual_animation"
            />
          </div>
        </li>
        <li className="item_usage">
          <div className="box_txt">
            <em className="ico_intro ico_subtit2">온라인 활용</em>
            <span className="ico_intro ico_usage2" role="text">
              사용처에 제출하면 <br />
              빠르게 인증 완료
            </span>
          </div>
          <div className="wrap_animation">
            <img
              src="https://t1.kakaocdn.net/zzng_static/images/20240709_134302/idcard/intro/intro_benefit2_240627.gif"
              alt=""
              className="visual_animation"
            />
          </div>
        </li>
        <li className="item_usage">
          <div className="box_txt">
            <em className="ico_intro ico_subtit3">오프라인 활용</em>
            <span className="ico_intro ico_usage3" role="text">
              뒤집어서 보여주면, <br />
              손쉽게 확인 완료
            </span>
          </div>
          <div className="wrap_animation">
            <img
              src="https://t1.kakaocdn.net/zzng_static/images/20240709_134302/idcard/intro/intro_benefit3.gif"
              alt=""
              className="visual_animation"
            />
          </div>
        </li>
      </ul>
    </div>
  </div>
);

export default WrapUsage;
