import {useEffect} from 'react';

import {closeWebView} from '@zzng/common/scheme/web';

import Header from '@/components/Header';
import Layout from '@/components/Layout';
import Lottie from '@/components/Lottie';
import MainContent from '@/components/MainContent';
import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import {useHistoryBackAOS} from '@/hooks/useHistoryBackAOS';
import useTiara from '@/hooks/useTiara';
import {ProgressState} from '@/models/issue';
import useRouteByProgressState from '@/pages/issue/progress/hooks/useRouteByProgressState';
import {PopupType, usePopupStore} from '@/store/popup';

const IssueProgressPage = () => {
  const {trackPage, trackEvent} = useTiara({pageName: TIARA_PAGE_NAME.ISSUE_PROGRESS});
  const {progressState} = useRouteByProgressState();
  const {isOpen, togglePopup, setConfirmBtnClick, openPopup} = usePopupStore();
  const isNeedIssueState = progressState === ProgressState.NEED_ISSUE;
  const popupType = isNeedIssueState ? PopupType.PROGRESSING : PopupType.STOP;

  const onClickClose = () => {
    trackEvent({action: TIARA_ACTION.CLOSE_CLICK});
    openPopup(popupType);
  };

  useHistoryBackAOS(() => {
    if (!isOpen) {
      trackEvent({action: TIARA_ACTION.CLOSE_CLICK});
    }

    togglePopup(popupType);
  }, [isOpen]);

  useEffect(() => {
    if (isNeedIssueState) {
      trackPage({action: TIARA_ACTION.ISSUE_PROGRESS_NEED_ISSUE_VIEW});
    } else {
      trackPage({action: TIARA_ACTION.ISSUE_PROGRESS_VIEW});
    }

    setConfirmBtnClick(() => closeWebView());
  }, []);

  return (
    <Layout className="container-bridge">
      <Header onClickClose={onClickClose} title="톡학생증 발급 대기"></Header>
      <MainContent className="content-full">
        <div className="inner_g">
          <div className="group_empty">
            <strong className="tit_g type4">
              <span className="item_txt" role="text">
                <span className="txt_tit">잠시만 기다려주세요</span>
              </span>
            </strong>
            {isNeedIssueState && (
              <p className="txt_g type4">
                전자증명서를 발급하여
                <br /> 톡학생증을 만들고 있습니다.
              </p>
            )}
            <Lottie id="animation_progress3" />
          </div>
        </div>
      </MainContent>
    </Layout>
  );
};

export default IssueProgressPage;
