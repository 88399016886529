import {useEffect, useLayoutEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {__html, addTermsEvent} from '@zzng/common/utils/termsEvent';

import Halfview from '@/components/Halfview';
import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import {useHistoryBackAOS} from '@/hooks/useHistoryBackAOS';
import useTiara from '@/hooks/useTiara';
import {useHalfviewStore} from '@/store/halfview';

const MarketingUsage = () => {
  const {trackPage, trackEvent} = useTiara({pageName: TIARA_PAGE_NAME.MARKETING_USAGE_TERMS});
  const navigate = useNavigate();
  const {setHalfviewClassname} = useHalfviewStore();

  const handleBackEvent = () => {
    trackEvent({action: TIARA_ACTION.BACK_CLICK});
    navigate(-1);
  };

  useHistoryBackAOS(handleBackEvent);

  useLayoutEffect(() => {
    setHalfviewClassname('modal_full modal_policy');
  }, []);

  useEffect(() => {
    trackPage({action: TIARA_ACTION.MARKETING_USAGE_TERMS_VIEW});
  }, []);

  useEffect(addTermsEvent, []);

  return (
    <>
      <Halfview.Header className="header_type2" onClickPrev={handleBackEvent} />
      <Halfview.Body>
        <div className="inner_g" dangerouslySetInnerHTML={{__html}} />
      </Halfview.Body>
    </>
  );
};

export default MarketingUsage;
