import {AxiosError, AxiosResponse} from 'axios';

import {logAxiosError} from '@zzng/common/utils/axiosInterceptor';
import {convertSentryErrorLevel} from '@zzng/common/utils/matrix';

import {PromotionErrorCode} from '@/constants/promotionErrorCode';
import DeviceNetworkError from '@/models/errors/DeviceNetworkError';
import ExpiredPilsnerAuthError from '@/models/errors/ExpiredPilsnerAuthError';
import HttpStatusError from '@/models/errors/HttpStatusError';
import ServerCodeError from '@/models/errors/ServerCodeError';

export const axiosErrorHandler = (errorResponse: AxiosResponse) => {
  if (errorResponse instanceof AxiosError) {
    logAxiosError(errorResponse);
    convertSentryErrorLevel(errorResponse.response?.status);

    if (errorResponse.code === AxiosError.ERR_NETWORK) {
      throw new DeviceNetworkError();
    }

    const {status} = errorResponse;
    const errorResponseBody = errorResponse.response?.data;
    const errorResponseStatus = errorResponse.response?.status;

    if (HttpStatusError.isServerHttpStatusError(errorResponseBody)) {
      throw new ServerCodeError({
        status,
        code: errorResponseBody.code,
        message: errorResponseBody.message
      });
    }

    // 필스너 인증 에러일 경우, 서비스 서버에서 상태 코드를 넘겨줄 수 없어 아래와 같이 처리함
    // 프로모션 API에서는 401 에러가 발생하면 로그인이 필요하다는 의미이므로, 필스너 인증 에러로 처리하지 않음
    if (errorResponseStatus === 401 && errorResponseBody?.code !== PromotionErrorCode.LOGIN_REQUIRED) {
      throw new ExpiredPilsnerAuthError();
    }
  }

  // 그외의 에러
  return Promise.reject(errorResponse);
};
