import {useEffect} from 'react';

import {SCHEME} from '@zzng/common/constants/scheme';
import {closeWebView} from '@zzng/common/scheme/web';
import {executeAppScheme} from '@zzng/common/utils/scheme';

import Header from '@/components/Header';
import Layout from '@/components/Layout';
import MainContent from '@/components/MainContent';
import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import {useHistoryBackAOS} from '@/hooks/useHistoryBackAOS';
import useTiara from '@/hooks/useTiara';
import {useCardIdStore} from '@/store/cardId';
import {useUniversity} from '@/store/university';

const Complete = () => {
  const {cardId} = useCardIdStore();
  const {identifier} = useUniversity();
  const {trackPage, trackEvent} = useTiara({pageName: TIARA_PAGE_NAME.ISSUE_COMPLETE});

  const handleCloseEvent = () => {
    trackEvent({action: TIARA_ACTION.CLOSE_CLICK});
    closeWebView();
  };

  useHistoryBackAOS(handleCloseEvent);

  const openCardDetailClick = () => {
    trackEvent({action: TIARA_ACTION.CARD_VIEW_CLICK});
    const cardScheme = `${SCHEME.CARD_DETAIL}?id=${cardId}`;
    executeAppScheme(cardScheme);
  };

  useEffect(() => {
    trackPage({
      action: TIARA_ACTION.ISSUE_COMPLETE_VIEW,
      customProps: {identifier: String(identifier)}
    });
  }, []);

  return (
    <Layout className="container-bridge">
      <Header title="톡학생증 발급 완료" onClickClose={handleCloseEvent} />
      <MainContent className="content-full">
        <div className="inner_g">
          <div className="group_empty">
            <span className="icon icon_complete" />
            <strong className="tit_g type4">
              <span className="item_txt" role="text">
                <span className="txt_tit">발급 완료</span>
              </span>
            </strong>
            <p className="txt_g type4">
              발급이 완료되면
              <br /> 카카오톡 메시지가 발송됩니다.
            </p>
          </div>
        </div>
        <div className="fixed_btm">
          <button type="button" className="btn btn_gray" onClick={openCardDetailClick}>
            카드보기
          </button>
        </div>
      </MainContent>
    </Layout>
  );
};

export default Complete;
