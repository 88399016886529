import {getHistory} from '@zzng/common/utils/history';

import {ROUTES} from '@/constants/routes';
import {ServerErrorCode} from '@/constants/serverErrorCode';
import DeviceNetworkError from '@/models/errors/DeviceNetworkError';
import ExpiredPilsnerAuthError from '@/models/errors/ExpiredPilsnerAuthError';
import ServerCodeError from '@/models/errors/ServerCodeError';

const handleServerCodeError = (error: ServerCodeError) => {
  const history = getHistory();
  switch (error.code) {
    case ServerErrorCode.INVALID_PHONE_NUMBER:
      history.replace(ROUTES.INVALID_PHONE_NUMBER);
      break;
    case ServerErrorCode.INVALID_LOGIN_STATUS:
      history.replace(ROUTES.LOGIN_ERROR);
      break;
    case ServerErrorCode.INVALID_UNIVERSITY_DEGREE:
      history.replace(ROUTES.INVALID_UNIVERSITY_DEGREE);
      break;
    case ServerErrorCode.INVALID_STUDENT_CREDENTIAL:
    case ServerErrorCode.INVALID_STUDENT_IDENTIFIER:
      history.replace(ROUTES.INVALID_STUDENT);
      break;
    case ServerErrorCode.INVALID_CREDENTIAL_CATEGORY:
      history.replace(ROUTES.INVALID_CREDENTIAL_CATEGORY);
      break;
    case ServerErrorCode.SIGN_FAILED:
      history.replace(ROUTES.SIGN_FAILED);
      break;
    case ServerErrorCode.DIGITAL_CARD_ERROR:
      history.replace(ROUTES.DIGITAL_CARD_ERROR);
      break;
    case ServerErrorCode.UNDER_MAINTENANCE:
      history.replace(ROUTES.SERVICE_MAINTENANCE_ERROR, {
        message: error.message
      });
      break;
    case ServerErrorCode.UNDER_MAINTENANCE_UNIVERSITY:
      history.replace(ROUTES.UNDER_MAINTENANCE_UNIVERSITY);
      break;
    case ServerErrorCode.INVALID_DEVICE:
      history.replace(ROUTES.INVALID_DEVICE);
      break;
    case ServerErrorCode.CIRCUIT_OPEN:
      history.replace(ROUTES.CIRCUIT_OPEN);
      break;
    case ServerErrorCode.EXCESSIVE_REQUESTS:
      history.replace(ROUTES.EXCESSIVE_REQUESTS);
      break;
    case ServerErrorCode.ABUSED_USER:
    case ServerErrorCode.INVALID_AUTH:
    default:
      history.replace(ROUTES.SERVER_ERROR);
  }
};

export const globalErrorHandler = () => {
  const history = getHistory();

  window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
    const error = event?.reason;

    if (error instanceof DeviceNetworkError) {
      history.replace(ROUTES.NETWORK_ERROR, {
        returnUrl: location.href
      });
      return;
    }

    if (error instanceof ServerCodeError) {
      handleServerCodeError(error);
      return;
    }

    if (error instanceof ExpiredPilsnerAuthError) {
      history.replace(ROUTES.NETWORK_ERROR, {
        returnUrl: PILSNER_ORIGIN + location.pathname + location.search
      });
      return;
    }

    // 정의되지 않은 에러
    history.replace(ROUTES.SERVER_ERROR);
  });
};
