import MainContent from '@/components/MainContent';
import ConfirmButtonBottom from '@/pages/error/components/ConfirmButtonBottom';

const InvalidStudent = () => {
  return (
    <MainContent className="content-full">
      <div className="inner_g">
        <div className="group_empty">
          <strong className="tit_g type4">
            <span className="item_txt" role="text">
              <span className="txt_tit">
                조회 가능한
                <br /> 학적이 없습니다
              </span>
            </span>
          </strong>
          <p className="txt_g type4">신청정보 확인 후 다시 시도해 주세요.</p>
          <span className="icon icon_issuance4"></span>
        </div>
      </div>
      <ConfirmButtonBottom />
    </MainContent>
  );
};

export default InvalidStudent;
