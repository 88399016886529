import {useEffect, useState} from 'react';

import {UniversitiesRes} from '@/models/Universities';
import {api} from '@/utils/api';

/** 모든 대학 조회 */
const useAllUniversities = () => {
  const [universities, setUniversities] = useState<UniversitiesRes>([]);

  const fetch = async () => {
    const res = await api<UniversitiesRes>('get', '/api/universities/all', {active: true});

    if (res.status === 200) {
      setUniversities(res.data);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return {
    universities
  };
};

export default useAllUniversities;
