interface Props {
  requestEmoticon: () => void;
}

const WrapEmoticon = ({requestEmoticon}: Props) => {
  const onClickEmoticon = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    requestEmoticon();
  };

  return (
    <div className="wrap_emoticon">
      <div className="inner_wrap">
        <div className="box_top">
          <h3 className="tit_top">
            <span className="ico_intro ico_emph1">스페셜 혜택</span>
          </h3>
          <span className="ico_intro ico_tit1" role="text">
            톡학생증 전용 <strong>춘식이 이모티콘</strong>
          </span>
        </div>
        <div className="item_emoticon">
          <div className="wrap_ico">
            <span className="ico_intro ico_emoticon1" />
            <span className="ico_intro ico_emoticon2" />
            <span className="ico_intro ico_emoticon3" />
            <span className="ico_intro ico_emoticon4" />
            <span className="ico_intro ico_emoticon5" />
            <span className="ico_intro ico_emoticon6" />
            <span className="ico_intro ico_emoticon7" />
            <span className="ico_intro ico_emoticon8" />
            <span className="ico_intro ico_emoticon9" />
            <span className="ico_intro ico_emoticon10" />
            <span className="ico_intro ico_emoticon11" />
            <span className="ico_intro ico_emoticon12" />
            <span className="ico_intro ico_emoticon13" />
            <span className="ico_intro ico_emoticon14" />
            <span className="ico_intro ico_emoticon15" />
          </div>
          <div className="box_txt">
            <span className="ico_intro ico_info1" role="text">
              다운로드 받은 일로부터 <strong>1년간</strong> 사용할 수 있어요.
            </span>
          </div>
        </div>
        <div className="group_btn">
          <a href="#none" className="btn_intro" onClick={onClickEmoticon}>
            <span className="ico_intro ico_btn1">톡학생증 만들고 이모티콘 받기</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default WrapEmoticon;
