import {useEffect, useLayoutEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {appendEulReulToKrString, appendEunNunToKrString} from '@zzng/common/utils/string';

import Halfview from '@/components/Halfview';
import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import {useHistoryBackAOS} from '@/hooks/useHistoryBackAOS';
import useTiara from '@/hooks/useTiara';
import {useHalfviewStore} from '@/store/halfview';
import {useUniversity} from '@/store/university';

const PublisherTerms = () => {
  const {trackPage, trackEvent} = useTiara({pageName: TIARA_PAGE_NAME.ISSUE_PUBLISHER_TERMS});
  const navigate = useNavigate();
  const {setHalfviewClassname} = useHalfviewStore();
  const {publisher} = useUniversity();
  const handleBackEvent = () => {
    trackEvent({action: TIARA_ACTION.BACK_CLICK});
    navigate(-1);
  };

  useHistoryBackAOS(handleBackEvent);

  useLayoutEffect(() => {
    setHalfviewClassname('modal_full modal_policy');
  }, []);

  useEffect(() => {
    trackPage({action: TIARA_ACTION.ISSUE_PUBLISHER_TERMS_VIEW});
  }, []);

  return (
    <>
      <Halfview.Header className="header_type2" onClickPrev={handleBackEvent} />
      <Halfview.Body>
        <div className="inner_g">
          <h1 className="tit_g type4">
            <span className="item_txt" role="text">
              <span className="txt_tit">{publisher?.name} 개인정보 제3자 제공 동의</span>
            </span>
          </h1>
          <p className="txt_g type2">
            {appendEunNunToKrString(publisher?.name)} 아래의 목적으로 개인정보를 (주)카카오에 제공하며, 회원의
            개인정보를 안전하게 처리하는데 최선을 다합니다.
          </p>
          <h2 className="tit_g type6">
            <span className="item_txt" role="text">
              <span className="txt_tit">[필수] 개인정보 제3자 제공동의</span>
            </span>
          </h2>
          <div className="group_tbl">
            <table className="tbl_g tbl_g2">
              <caption className="ir_caption">[필수] 개인정보 제3자 제공동의</caption>
              <colgroup>
                <col style={{width: '25%'}} />
                <col style={{width: '25%'}} />
                <col style={{width: '25%'}} />
                <col style={{width: '25%'}} />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">제공받는자</th>
                  <th scope="col">목적</th>
                  <th scope="col">항목</th>
                  <th scope="col">보유기간</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <em className="emph_text">(주)카카오</em>
                  </td>
                  <td>
                    <em className="emph_text">학생정보 확인용 증명서 발급</em>
                  </td>
                  <td>학생정보 확인용 증명서</td>
                  <td>
                    <em className="emph_text">카드 발급 시 지체없이 파기</em>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className="txt_g type2">
            위 동의는 {appendEulReulToKrString(publisher?.name)} 통해 톡학생증 발급 자격 증빙에 필요한 졸업 및 재학,
            재적 증명서를 발급하는 내용을 포함하고 있습니다.
          </p>
          <p className="txt_g type2">위 동의를 거부할 권리가 있으며, 동의를 거부하실 경우 서비스 이용이 제한됩니다.</p>
        </div>
      </Halfview.Body>
    </>
  );
};

export default PublisherTerms;
