import {useEffect, useRef} from 'react';
import {useNavigate, useOutletContext} from 'react-router-dom';

import Halfview from '@/components/Halfview';
import {ROUTES} from '@/constants/routes';
import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import useTiara from '@/hooks/useTiara';
import {Degree} from '@/models/Universities';
import {useHalfviewStore} from '@/store/halfview';
import {useUniversity} from '@/store/university';

import {IssueOutletContext} from '..';

const DegreeComponent = () => {
  const outletContext = useOutletContext<IssueOutletContext>();
  const {trackPage, trackEvent} = useTiara({pageName: TIARA_PAGE_NAME.ISSUE_DGREE_SELECTION});
  const navigate = useNavigate();
  const {setOpen} = useHalfviewStore();
  const {degrees, setSelectedDegree} = useUniversity();

  /** 학위 선택이 발생했는지 여부 */
  const isDegreeSelected = useRef<boolean>(false);

  const onClickDegree = (degree: Degree) => {
    trackEvent({
      action: TIARA_ACTION.DEGREE_CLICK,
      customProps: {identifier: degree.identifier}
    });
    isDegreeSelected.current = true;
    setSelectedDegree(degree);
    setOpen(false);
  };

  /** 하프뷰가 닫힌 경우 */
  const onExited = () => {
    trackEvent({action: TIARA_ACTION.CLOSE_CLICK});
    navigate(ROUTES.ISSUE);
    // 학교 선택하여 닫는 경우 학위 선택으로 바로 이동
    if (isDegreeSelected.current) {
      isDegreeSelected.current = false;
      outletContext.onExitedWithDegreeSelected();
    }
  };

  useEffect(() => {
    trackPage({action: TIARA_ACTION.ISSUE_DGREE_SELECTION_VIEW});
  }, []);

  if (!degrees.length) return null;

  return (
    <Halfview.Wrapper classnames="modal_half" onExited={onExited}>
      <Halfview.Header className="header_type2">
        <h4 id="modalTtile" className="tit_header">
          <span role="text">학위를 선택해 주세요.</span>
        </h4>
      </Halfview.Header>
      <Halfview.Body>
        <div className="group_list type2">
          <div className="inner_g">
            <ul className="list_g list_btn" role="list">
              {degrees.map((degree) => (
                <li key={degree.identifier} role="listitem">
                  <button type="button" className="btn_list" role="button" onClick={() => onClickDegree(degree)}>
                    <span className="txt_btn">{degree.name}</span>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Halfview.Body>
    </Halfview.Wrapper>
  );
};

export default DegreeComponent;
