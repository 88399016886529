import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import Halfview from '@/components/Halfview';
import {ROUTES} from '@/constants/routes';
import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import {useHistoryBackAOS} from '@/hooks/useHistoryBackAOS';
import useTiara from '@/hooks/useTiara';
import {useHalfviewStore} from '@/store/halfview';

const CertificateIssueAgreement = () => {
  const navigate = useNavigate();
  const {setOpen, open} = useHalfviewStore();
  const {trackPage, trackEvent} = useTiara({pageName: TIARA_PAGE_NAME.CERTIFICATE_ISSUE_AGREEMENT});

  const onExited = () => {
    // NOTE: 뒤로가기를 눌러서 닫힐 경우에는 CLOSE_CLICK 이벤트가 잡히지 않도록 처리
    open && trackEvent({action: TIARA_ACTION.CLOSE_CLICK});
    navigate(ROUTES.ISSUE_CONFIRM);
  };

  const handleBackEvent = () => {
    trackEvent({action: TIARA_ACTION.BACK_CLICK});
    setOpen(false);
  };

  useHistoryBackAOS(handleBackEvent);

  useEffect(() => {
    trackPage({action: TIARA_ACTION.ISSUE_CONFIRM_CERTIFICATE_ISSUE_AGREEMENT_VIEW});
  }, []);

  return (
    <Halfview.Wrapper classnames="modal_full modal_policy" onExited={onExited}>
      <Halfview.Header className="header_type2" onClickPrev={handleBackEvent} />
      <Halfview.Body>
        <div className="inner_g inner_privacy_agree">
          <h1 className="tit_g type4">
            <span className="item_txt" role="text">
              <span className="txt_tit">증명서 발급 동의</span>
            </span>
          </h1>
          <p className="txt_g type2">
            톡학생증 발급 자격 증빙을 위해 증명서발급대행기관을 통한 졸업 및 재적증명서 발급에 동의합니다.
          </p>
        </div>
      </Halfview.Body>
    </Halfview.Wrapper>
  );
};

export default CertificateIssueAgreement;
