export interface PostSignRes {
  txId: string;
}

export interface PostTermsRes {
  name: string;
  birthdate: number;
}

export interface GetTermsRes {
  needToShowChannel: boolean;
  needToShowMarketing: boolean;
}

export interface PostOfferRes {
  credentialTransactionId: string;
  redirectUrl: string;
}

// NOTE: state, reason, credentialTransactionId은 사용하지 않는 값. 서버에서 클래스를 발급결과 조회에서도 같이 사용하여 들어있는 값
export interface PostIssueRes {
  credentialTransactionId: string;
  state: string;
  reason: string;
  // NOTE: presentationUrl : '디카2.0제출 -> 톡학생증 발급'을 통해 진입했을 때 넘어오는 값
  presentationUrl?: string;
  cardId: string;
}

export enum ProgressState {
  NEED_ISSUE = 'need_issue', // 발급 요청 이전 상태
  COMPLETE_SIGN = 'complete_sign' // 서명 이후 서명 성공 확인이 필요한 상태
}
