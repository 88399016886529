const WrapIntro = () => (
  <div className="wrap_intro">
    <div className="inner_wrap ir_pm">
      <strong>인증부터 전용 혜택까지</strong>
      <h2>
        <span role="text">
          <em>톡학생증</em> 지금 받아보세요
        </span>
      </h2>
      <span>대학생,졸업생,대학원생 모두 모여!</span>
      <span>모바일로 간편하게, 졸업생도 가능!</span>
      <img
        src="https://t1.kakaocdn.net/zzng_static/images/20240115_101046/idcard-intro/img_intro01_240115.png"
        alt=""
        className="img_intro"
      />
    </div>
  </div>
);

export default WrapIntro;
