import {openExternalWebview} from '@zzng/common/scheme/web';

import MainContent from '@/components/MainContent';
import Space from '@/components/Space';
import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import {UNIVERSITY_UNAVAILABLE_REASON} from '@/constants/urlLink';
import useTiara from '@/hooks/useTiara';
import ConfirmButtonBottom from '@/pages/error/components/ConfirmButtonBottom';

const UnderMaintenanceUniversity = () => {
  const {trackEvent} = useTiara({pageName: TIARA_PAGE_NAME.ISSUE_FAIL});

  const onClickCS = () => {
    trackEvent({action: TIARA_ACTION.CUSTOMER_SERVICE_CLICK});
    openExternalWebview(UNIVERSITY_UNAVAILABLE_REASON, {
      isCurrentWebviewClose: false
    });
  };
  return (
    <MainContent className="content-full">
      <div className="inner_g">
        <div className="group_empty">
          <strong className="tit_g type4">
            <span className="item_txt" role="text">
              <span className="txt_tit">
                해당 학교의 사정으로
                <br /> 발급이 불가합니다
              </span>
            </span>
          </strong>
          <p className="txt_g type4">
            현재
            <Space />
            <a href="#none" onClick={onClickCS} className="link_g txt_blue" target="_blank">
              학교의 사정
            </a>
            으로
            <br /> 톡학생증을 발급할 수 없습니다.
          </p>
          <span className="icon icon_issuance4"></span>
        </div>
      </div>
      <ConfirmButtonBottom />
    </MainContent>
  );
};

export default UnderMaintenanceUniversity;
