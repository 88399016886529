import {SERVICE} from '@zzng/common/constants/service';

import MainContent from '@/components/MainContent';

const InvalidPhoneNumber = () => {
  return (
    <MainContent className="content-full">
      <div className="inner_g">
        <div className="group_empty">
          <span className="emoticon type1"></span>
          <strong className="tit_g">
            <span className="item_txt" role="text">
              <span className="txt_tit">
                국내 전화번호로 인증된
                <br /> 카카오톡으로 이용해 주세요
              </span>
            </span>
          </strong>
          <p className="txt_g">
            {SERVICE.STUDENTID} 서비스는 국내 전화번호로 인증된
            <br /> 카카오톡으로만 이용할 수 있습니다.
          </p>
        </div>
      </div>
    </MainContent>
  );
};

export default InvalidPhoneNumber;
