import {useEffect, useLayoutEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {openExternalWebview} from '@zzng/common/scheme/web';

import Halfview from '@/components/Halfview';
import Space from '@/components/Space';
import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import {KAKAO_PRIVACY_POLICY} from '@/constants/urlLink';
import {useHistoryBackAOS} from '@/hooks/useHistoryBackAOS';
import useTiara from '@/hooks/useTiara';
import {useHalfviewStore} from '@/store/halfview';

const KakaoCollectUseTerms = () => {
  const {trackPage, trackEvent} = useTiara({pageName: TIARA_PAGE_NAME.ISSUE_KAKAO_COLLECT_USE});
  const navigate = useNavigate();
  const {setHalfviewClassname} = useHalfviewStore();

  const handleBackEvent = () => {
    trackEvent({action: TIARA_ACTION.BACK_CLICK});
    navigate(-1);
  };

  const onClickPrivacyPolicy = () => {
    openExternalWebview(KAKAO_PRIVACY_POLICY, {
      isCurrentWebviewClose: false
    });
  };

  useHistoryBackAOS(handleBackEvent);

  useLayoutEffect(() => {
    setHalfviewClassname('modal_full modal_policy');
  }, []);

  useEffect(() => {
    trackPage({action: TIARA_ACTION.ISSUE_KAKAO_COLLECT_USE_VIEW});
  }, []);

  return (
    <>
      <Halfview.Header className="header_type2" onClickPrev={handleBackEvent} />
      <Halfview.Body>
        <div className="inner_g">
          <h1 className="tit_g type4">
            <span className="item_txt" role="text">
              <span className="txt_tit">카카오 개인정보 수집 및 이용 동의</span>
            </span>
          </h1>
          <p className="txt_g type2">
            (주)카카오는 아래 목적으로 개인정보를 수집 및 이용하며, 회원의 개인정보를 안전하게 취급하는데 최선을
            다합니다.
          </p>
          <h2 className="tit_g type6">
            <span className="item_txt" role="text">
              <span className="txt_tit">[필수] 개인정보 수집 및 이용동의</span>
            </span>
          </h2>
          <div className="group_tbl">
            <table className="tbl_g tbl_g2">
              <caption className="ir_caption">[필수] 개인정보 수집 및 이용동의</caption>
              <colgroup>
                <col style={{width: '30%'}} />
                <col style={{width: '40%'}} />
                <col style={{width: '30%'}} />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">목적</th>
                  <th scope="col">항목</th>
                  <th scope="col">보유기간</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <em className="emph_text">톡학생증 발급 및 카카오 내 톡학생증 관련 서비스 이용</em>
                  </td>
                  <td>이름, 생년월일, 학교, 학위, 학번, 학과, 학적 상태</td>
                  <td>
                    <em className="emph_text">
                      카드 삭제 시 지체없이 파기 (일부 정보는 발급 이력 관리를 위해 카드 삭제 후에도 아래 기간 동안
                      보관합니다.
                      <br />- 학교, 학번 : 카드 발급일로부터 2년 보관, 학적 상태 : 회원 탈퇴 시까지 보관)
                    </em>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className="txt_g type2">위 동의를 거부할 권리가 있으며, 동의를 거부하실 경우 서비스 이용이 제한됩니다.</p>
          <p className="txt_g type2">
            더 자세한 내용에 대해서는
            <Space />
            <a href="#none" onClick={onClickPrivacyPolicy} className="txt_blue">
              카카오 개인정보처리방침
            </a>
            을 참고하 시기 바랍니다.
          </p>
        </div>
      </Halfview.Body>
    </>
  );
};

export default KakaoCollectUseTerms;
