import {create} from 'zustand';

export enum PopupType {
  STOP = 'stop',
  PROGRESSING = 'progressing',
  MAINTENANCE = 'maintenance',
  /** 서비스 점검 학교 목록 안내 팝업 */
  MAINTENANCE_LIST = 'maintenance_list'
}

type PopupStore = {
  isOpen: boolean;
  type: PopupType;
  openPopup: (type: PopupType) => void;
  closePopup: () => void;
  togglePopup: (type: PopupType) => void;
  confirmBtnClick: () => void;
  setConfirmBtnClick: (confirmBtnClick: () => void) => void;
};

export const usePopupStore = create<PopupStore>((set) => ({
  isOpen: false,
  type: PopupType.STOP,
  openPopup: (type) => {
    set((prev) => ({...prev, isOpen: true, type}));
  },
  closePopup: () => {
    set((prev) => ({...prev, isOpen: false}));
  },
  togglePopup: (type) => {
    set((prev) => ({...prev, isOpen: !prev.isOpen, type}));
  },
  confirmBtnClick: () => {},
  setConfirmBtnClick: (confirmBtnClick: () => void) => {
    set((prev) => ({...prev, confirmBtnClick}));
  }
}));
