import {useEffect} from 'react';
import {Outlet} from 'react-router-dom';

import {closeWebView} from '@zzng/common/scheme/web';

import Header from '@/components/Header';
import Layout from '@/components/Layout';
import {
  TIARA_ACTION,
  TIARA_COMMON_ERROR_ACTION_MAP,
  TIARA_ISSUE_ERROR_ACTION_MAP,
  TIARA_PAGE_NAME
} from '@/constants/tiara';
import {useHistoryBackAOS} from '@/hooks/useHistoryBackAOS';
import useTiara from '@/hooks/useTiara';
import {useUniversity} from '@/store/university';

const ErrorOutlet = () => {
  const issueFailedAction = TIARA_ISSUE_ERROR_ACTION_MAP[location.pathname];
  const commonErrorAction = TIARA_COMMON_ERROR_ACTION_MAP[location.pathname];
  const {identifier} = useUniversity();
  const {trackPage, trackEvent} = useTiara({
    pageName: issueFailedAction ? TIARA_PAGE_NAME.ISSUE_FAIL : TIARA_PAGE_NAME.ERROR
  });

  const onClickClose = () => {
    trackEvent({action: TIARA_ACTION.CLOSE_CLICK});
    closeWebView();
  };

  useHistoryBackAOS(() => onClickClose());

  useEffect(() => {
    trackPage({
      action: issueFailedAction ?? commonErrorAction,
      customProps: {...(issueFailedAction && identifier && {identifier: String(identifier)})}
    });
  }, []);

  return (
    <Layout className="container-bridge">
      <Header title={`톡학생증 발급 ${issueFailedAction ? '실패' : '오류'}`} onClickClose={onClickClose} />
      <Outlet />
    </Layout>
  );
};

export default ErrorOutlet;
