type Props = {
  onClick: () => void;
};

const IssueButton = ({onClick}: Props) => (
  <div className="fixed_btm type2">
    <button type="button" className="btn_now" onClick={onClick}>
      <span className="ico_intro ico_create">톡학생증 만들고 혜택 받기</span>
    </button>
  </div>
);

export default IssueButton;
