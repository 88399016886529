import axios from 'axios';

import {getApiClient} from '@zzng/common/utils/api';

import {axiosErrorHandler} from '@/utils/axiosInterceptors';

export type Params = Record<string, any>;

// 필스너의 TIMEOUT 시간(30초)와 맞춤
// https://kakao.agit.in/g/259178/wall/373982709#comment_panel_377984151
const REQUEST_TIMEOUT_VALUE = 30000;

const instance = axios.create({
  timeout: REQUEST_TIMEOUT_VALUE
});

instance.interceptors.response.use(undefined, axiosErrorHandler);

export const api = getApiClient(instance);
