import {useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';

import {ROUTES} from '@/constants/routes';
import {ProgressState} from '@/models/issue';
import {getSignAndPostTerms, postIssue} from '@/pages/issue/progress/api';
import {useAgreementStatusStore} from '@/store/agreementStatus';
import {useCardIdStore} from '@/store/cardId';
import {usePresentStore} from '@/store/presentStore';
import {useUniversity} from '@/store/university';
import {useUserStore} from '@/store/user';

const useRouteByProgressState = () => {
  const {optionalAgreementStatus} = useAgreementStatusStore();
  const {selectedDegree, studentId, identifier, publisher} = useUniversity();
  const {setBirthDate, setName} = useUserStore();
  const {setPresentationUrl} = usePresentStore();
  const {setCardId} = useCardIdStore();
  const {pTxId} = usePresentStore();

  const {isChannelPlus, isMarketingUsage} = optionalAgreementStatus;

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const txId = searchParams.get('txId'); // 서명 이후 넘어오는 값
  const progressState = txId ? ProgressState.COMPLETE_SIGN : ProgressState.NEED_ISSUE;

  const routeByProgressState = async () => {
    switch (progressState) {
      case ProgressState.NEED_ISSUE: {
        const {presentationUrl, cardId} = await postIssue({
          publisherId: publisher?.identifier,
          universityIdentifier: identifier,
          degreeIdentifier: selectedDegree?.identifier,
          studentIdentifier: studentId,
          ...(pTxId && {presentationTxId: pTxId})
        });
        presentationUrl && setPresentationUrl(presentationUrl);
        setCardId(cardId);
        navigate(ROUTES.ISSUE_COMPLETE);
        break;
      }

      case ProgressState.COMPLETE_SIGN: {
        const {name, birthdate} = await getSignAndPostTerms({
          txId,
          publisherId: publisher?.identifier,
          channelPlus: isChannelPlus,
          isMarketingUsageAgreed: isMarketingUsage
        });

        setName(name);
        setBirthDate(birthdate);
        navigate(ROUTES.ISSUE_CONFIRM);
        break;
      }
      default:
    }
  };

  useEffect(() => {
    routeByProgressState();
  }, []);

  return {progressState};
};

export default useRouteByProgressState;
