import {useCallback, useState} from 'react';

let timer: ReturnType<typeof setTimeout>;

export const useToast = () => {
  const [show, setShow] = useState(false);
  const open = useCallback(() => {
    clearTimeout(timer);
    setShow(true);

    timer = setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [show]);

  const Toast = () => {
    if (!show) return null;

    return (
      <div className="box_toast">
        <span className="txt_toast">주소가 복사되었습니다.</span>
      </div>
    );
  };

  return [open, <Toast key="toast" />] as const;
};
