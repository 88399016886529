import {ServerErrorCode} from '@/constants/serverErrorCode';
import HttpStatusError from '@/models/errors/HttpStatusError';

export interface ServerCodeErrorBody {
  code: ServerErrorCode;
  message?: string;
}

class ServerCodeError extends HttpStatusError {
  code: ServerErrorCode;
  status: number;

  constructor({code, status, message}: ServerCodeErrorBody & {status: number}) {
    super(message);
    this.code = code;
    this.status = status;
  }
}

export default ServerCodeError;
