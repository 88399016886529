const copyByClipboard = async (copiedString: string) => {
  await navigator.clipboard.writeText(copiedString);
};

const copyByExecCommand = (copiedString: string) => {
  const copyingElement = document.createElement('input');
  copyingElement.style.cssText = `
    position: fixed;
    opacity: 0;
  `;
  copyingElement.setAttribute('readonly', 'readonly');
  document.body.appendChild(copyingElement);
  copyingElement.value = copiedString;
  copyingElement.focus();
  document.execCommand('selectAll');
  document.execCommand('copy');
  document.body.removeChild(copyingElement);
};

export default async (copiedString: string) => {
  try {
    await copyByClipboard(copiedString);
  } catch {
    // 안드로이드 웹뷰의 경우 clipboard 권한이 없어 DOMException: Write permission denied.가 발생함.
    // 이를 해결하기 위해 deprecated된 execCommand를 사용함
    copyByExecCommand(copiedString);
  }
};
