import {SCHEME} from '@zzng/common/constants/scheme';
import {openExternalWebview} from '@zzng/common/scheme/web';

import MainContent from '@/components/MainContent';
import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import useTiara from '@/hooks/useTiara';

const LoginError = () => {
  const {trackEvent} = useTiara({pageName: TIARA_PAGE_NAME.ERROR});

  const onClickKakaoLogin = () => {
    trackEvent({action: TIARA_ACTION.LOGIN_CONFIRM_CLICK});
    openExternalWebview(SCHEME.ACCOUNT, {isCurrentWebviewClose: true});
  };

  return (
    <MainContent className="content-full">
      <div className="inner_g">
        <div className="group_empty">
          <span className="emoticon type4"></span>
          <strong className="tit_g">
            <span className="item_txt" role="text">
              <span className="txt_tit">카카오계정으로 로그인해 주세요</span>
            </span>
          </strong>
          <p className="txt_g">카카오톡에 로그인된 카카오 계정이 없습니다.</p>
          <button type="button" className="btn btn_gray" onClick={onClickKakaoLogin}>
            확인
          </button>
        </div>
      </div>
    </MainContent>
  );
};

export default LoginError;
