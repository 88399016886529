import {useEffect} from 'react';
import cn from 'classnames';

import {isRequiredKakaoTalkVersionForStudent} from '@zzng/common/scheme/digitalcard';
import {closeWebView} from '@zzng/common/scheme/web';
import {isEmbedOrigin} from '@zzng/common/utils/isEmbedOrigin';

import Header from '@/components/Header';
import Layout from '@/components/Layout';
import MainContent from '@/components/MainContent';
import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import useKakaoSDK from '@/hooks/useKakaoSDK';
import {useLayoutClass} from '@/hooks/useLayoutClass';
import useTiara from '@/hooks/useTiara';
import IssueButton from '@/pages/intro/components/IssueButton';
import WrapBenefit from '@/pages/intro/components/WrapBenefit';
import WrapEmoticon from '@/pages/intro/components/WrapEmoticon';
import WrapIntro from '@/pages/intro/components/WrapIntro';
import WrapNotice from '@/pages/intro/components/WrapNotice';
import WrapShare from '@/pages/intro/components/WrapShare';
import WrapUsage from '@/pages/intro/components/WrapUsage';
import {usePromotion} from '@/pages/intro/hooks/usePromotion';
import {useToast} from '@/pages/intro/hooks/useToast';
import {sendTMS} from '@/utils/developers';

const Intro = () => {
  const [open, toastContext] = useToast();
  const {requestEmoticon, outletPopupContext} = usePromotion();
  const {trackEvent, trackPage} = useTiara({pageName: TIARA_PAGE_NAME.SERVICE_INTRO});
  const showHeader = isRequiredKakaoTalkVersionForStudent && !isEmbedOrigin();

  const onClickClose = () => {
    trackEvent({action: TIARA_ACTION.CLOSE_CLICK});
    closeWebView();
  };

  const onClickShare = () => {
    trackEvent({action: TIARA_ACTION.KAKAOTALK_SHARE_CLICK});
    sendTMS('INTRO');
  };

  const onClickRequestEmoticon = () => {
    trackEvent({action: TIARA_ACTION.EMOTICON_REQUEST_CLICK});
    requestEmoticon();
  };

  const onClickIssue = () => {
    trackEvent({action: TIARA_ACTION.INTRO_ISSUE_CLICK});
    requestEmoticon();
    // NOTE: 프로모션 연장해가는 것으로 합의는 해두었지만 이후 사업 상황에 따라 변경이 필요할 수도 있을 것 같아
    // 프로모션 이전 코드를 주석으로 남겨둡니다.
    // if (isDesktop) {
    //   navigate(ROUTES.ONLY_MOBILE);
    //   return;
    // }

    // openDigitalCard(`${STUDENT_ORIGIN}${ROUTES.ISSUE}`);
  };

  useLayoutClass();

  useKakaoSDK();

  useEffect(() => {
    trackPage({action: TIARA_ACTION.VIEW_SERVICE_INTRO});
  }, []);

  useEffect(() => {
    const CLASS_NAME = 'layout_type3';
    if (isEmbedOrigin()) {
      document.documentElement.classList.add(CLASS_NAME);
    }

    return () => {
      document.documentElement.classList.remove(CLASS_NAME);
    };
  }, []);

  return (
    <>
      {outletPopupContext}
      <Layout className={cn('container-intro', {type_noheader: !showHeader})}>
        {toastContext}
        {showHeader && <Header title="톡학생증" onClickClose={onClickClose} onClickShare={onClickShare} showTitle />}
        <MainContent className={cn({'content-full': isEmbedOrigin()})}>
          <WrapIntro />
          <WrapEmoticon requestEmoticon={onClickRequestEmoticon} />
          <WrapBenefit />
          <WrapUsage />
          <WrapShare openToast={open} />
          <WrapNotice />
          {!isEmbedOrigin() && <IssueButton onClick={onClickIssue} />}
        </MainContent>
      </Layout>
    </>
  );
};

export default Intro;
