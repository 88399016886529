import MainContent from '@/components/MainContent';
import ConfirmButtonBottom from '@/pages/error/components/ConfirmButtonBottom';

const DigitalCardError = () => {
  return (
    <MainContent className="content-full">
      <div className="inner_g">
        <div className="group_empty">
          <strong className="tit_g type4">
            <span className="item_txt" role="text">
              <span className="txt_tit">발급이 실패되었습니다</span>
            </span>
          </strong>
          <p className="txt_g type4">
            일시적인 오류가 발생했습니다.
            <br />
            잠시 후 다시 시도해 주세요.
          </p>
          <span className="icon icon_issuance4"></span>
        </div>
      </div>
      <ConfirmButtonBottom />
    </MainContent>
  );
};

export default DigitalCardError;
