import {create} from 'zustand';

type StatusBarColorStore = {
  dimmedCount: number;
  color: string;
  setColor: (color: string) => void;
  addDimmed: () => void;
  removeDimmed: () => void;
};

export const useStatusBarColorStore = create<StatusBarColorStore>((set) => ({
  dimmedCount: 0,
  color: '',
  setColor: (color) => {
    set({color});
  },
  addDimmed: () => {
    set(({dimmedCount}) => ({dimmedCount: dimmedCount + 1}));
  },
  removeDimmed: () => {
    set(({dimmedCount}) => ({dimmedCount: dimmedCount > 0 ? dimmedCount - 1 : 0}));
  }
}));
