import {useLayoutEffect} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';

import Halfview from '@/components/Halfview';
import {ROUTES} from '@/constants/routes';
import {TIARA_ACTION, TIARA_AGREEMENT_HALFVIEW_MAP} from '@/constants/tiara';
import {useHistoryBackAOS} from '@/hooks/useHistoryBackAOS';
import useTiara from '@/hooks/useTiara';
import {useAgreementStatusStore} from '@/store/agreementStatus';
import {useHalfviewStore} from '@/store/halfview';

const AgreementHalfviewOutlet = () => {
  const {trackEvent} = useTiara();
  const {setOpen} = useHalfviewStore();
  const {halfviewClassname} = useHalfviewStore();
  const navigate = useNavigate();
  const {initAgreementStatus} = useAgreementStatusStore();

  const onExited = () => {
    trackEvent({
      pageName: TIARA_AGREEMENT_HALFVIEW_MAP[location.pathname],
      action: TIARA_ACTION.CLOSE_CLICK
    });
    navigate(ROUTES.ISSUE);
  };

  useHistoryBackAOS(() => setOpen(false));

  useLayoutEffect(() => {
    initAgreementStatus();
  }, []);

  return (
    <Halfview.Wrapper classnames={halfviewClassname} onExited={onExited}>
      <Outlet />
    </Halfview.Wrapper>
  );
};

export default AgreementHalfviewOutlet;
