import {useLocation} from 'react-router-dom';

import MainContent from '@/components/MainContent';

interface LocationState {
  state: {
    message: string;
  };
}

// NOTE: 서버에서 넘겨주는 message 값으로 서비스 점검 시간 표시
const ServiceMaintenance = () => {
  const {
    state: {message}
  } = useLocation() as LocationState;

  return (
    <MainContent className="content-full">
      <div className="inner_g">
        <div className="group_empty">
          <span className="emoticon type5"></span>
          <strong className="tit_g">
            <span className="item_txt" role="text">
              <span className="txt_tit">서비스 점검 중</span>
            </span>
          </strong>
          <p className="txt_g error_text">{message}</p>
        </div>
      </div>
    </MainContent>
  );
};

export default ServiceMaintenance;
