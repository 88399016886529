import {useEffect} from 'react';
import {Navigate} from 'react-router-dom';

import {isRequiredKakaoTalkVersionForStudent} from '@zzng/common/scheme/digitalcard';
import {closeWebView} from '@zzng/common/scheme/web';

import Header from '@/components/Header';
import Layout from '@/components/Layout';
import MainContent from '@/components/MainContent';
import {ROUTES} from '@/constants/routes';
import {TIARA_ACTION, TIARA_COMMON_ERROR_ACTION_MAP, TIARA_PAGE_NAME} from '@/constants/tiara';
import {useHistoryBackAOS} from '@/hooks/useHistoryBackAOS';
import useTiara from '@/hooks/useTiara';

const NotFound = () => {
  const {trackPage, trackEvent} = useTiara({
    pageName: TIARA_PAGE_NAME.ERROR
  });

  const onClickClose = () => {
    trackEvent({action: TIARA_ACTION.CLOSE_CLICK});
    closeWebView();
  };

  useHistoryBackAOS(() => onClickClose());

  useEffect(() => {
    trackPage({
      action: TIARA_COMMON_ERROR_ACTION_MAP[ROUTES.NOT_FOUND]
    });
  }, []);

  if (!isRequiredKakaoTalkVersionForStudent) {
    return <Navigate to={ROUTES.ROOT} />;
  }

  return (
    <Layout className="container-bridge">
      <Header title="톡학생증 발급 오류" onClickClose={onClickClose} />
      <MainContent className="content-full">
        <div className="inner_g">
          <div className="group_empty">
            <span className="emoticon type8"></span>
            <strong className="tit_g">
              <span className="item_txt" role="text">
                <span className="txt_tit">페이지를 찾을 수 없습니다</span>
              </span>
            </strong>
            <p className="txt_g">주소를 다시 확인해 주세요.</p>
          </div>
        </div>
      </MainContent>
    </Layout>
  );
};

export default NotFound;
