import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import useTiara from '@/hooks/useTiara';
import {UniversitiesRes, UniversityData} from '@/models/Universities';
import {useHalfviewStore} from '@/store/halfview';
import {PopupType, usePopupStore} from '@/store/popup';
import {useUniversity} from '@/store/university';

type Props = {
  onSelectUniversity: () => void;
  universities: UniversitiesRes;
};

const UniversityList = ({onSelectUniversity, universities}: Props) => {
  const {trackEvent} = useTiara({pageName: TIARA_PAGE_NAME.ISSUE_UNIVERSITY_SEARCH});
  const {setOpen} = useHalfviewStore();
  const {setUniversity} = useUniversity();
  const {openPopup} = usePopupStore();

  const submitUniversity = (university: UniversityData) => {
    trackEvent({
      action: TIARA_ACTION.UNIVERSITY_NAME_CLICK,
      customProps: {identifier: String(university.identifier)}
    });

    if (university.maintenance) {
      openPopup(PopupType.MAINTENANCE);
      return;
    }

    onSelectUniversity();
    setUniversity(university);
    setOpen(false);
  };

  return (
    <div className="group_list">
      <div className="inner_g">
        <ul className="list_g list_badge" role="list">
          {universities.map((university) => (
            <li role="listitem" key={university.identifier}>
              <button type="button" className="btn_list" onClick={() => submitUniversity(university)}>
                <span className="item_list type_r" role="text">
                  <span className="txt_item type1">{university.name}</span>
                  <span className="screen_out">, </span>
                  {university.maintenance && <em className="badge_g type3">점검 중</em>}
                </span>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default UniversityList;
