import {ChangeEventHandler} from 'react';
import {NavLink} from 'react-router-dom';
import cn from 'classnames';

import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import useTiara from '@/hooks/useTiara';
import {AgreementItemType} from '@/pages/issue/agreement/index';

interface Props extends Omit<AgreementItemType, 'needToHide' | 'isRequired'> {
  idNumber: string;
  isRequired?: boolean;
}

const AgreementItem = ({checked, ariaLabel, idNumber, isRequired, agreementDetailRoute, setStatus}: Props) => {
  const {trackEvent} = useTiara({pageName: TIARA_PAGE_NAME.ISSUE_AGREEMENT});

  const isCheckAll = idNumber === 'all';

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const {checked} = e.target;
    if (isCheckAll) {
      if (checked) {
        trackEvent({action: TIARA_ACTION.ALL_AGREEMENT_CLICK});
      } else {
        trackEvent({action: TIARA_ACTION.ALL_AGREEMENT_CANCEL_CLICK});
      }
    } else {
      if (checked) {
        trackEvent({
          action: isRequired ? TIARA_ACTION.REQUIRED_AGREEMENT_CLICK : TIARA_ACTION.OPTIONAL_AGREEMENT_CLICK
        });
      } else {
        trackEvent({
          action: isRequired
            ? TIARA_ACTION.REQUIRED_AGREEMENT_CANCEL_CLICK
            : TIARA_ACTION.OPTIONAL_AGREEMENT_CANCEL_CLICK
        });
      }
    }
    setStatus(checked);
  };

  return (
    <div className={cn('item_g item_check', {item_detaillink: !isCheckAll, item_all: isCheckAll})}>
      <input
        type="checkbox"
        id={isCheckAll ? 'checkAll' : `checkItem${idNumber}`}
        name="serviceAgreement"
        className="inp_g"
        aria-label={ariaLabel}
        checked={checked}
        onChange={onChange}
      />
      <div className="wrap_lab">
        <span className="icon icon_input"></span>
        <label
          htmlFor={isCheckAll ? 'checkAll' : `checkItem${idNumber}`}
          id={!isCheckAll ? `agreeLab${idNumber}` : undefined}
          className="lab_g"
          aria-hidden="true"
        >
          <span className="txt_lab">{ariaLabel}</span>
        </label>
        {agreementDetailRoute && (
          <NavLink
            to={agreementDetailRoute}
            onClick={() => trackEvent({action: TIARA_ACTION.DETAIL_VIEW_CLICK})}
            className="link_more"
            aria-describedby={`agreeLab${idNumber}`}
          >
            보기
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default AgreementItem;
