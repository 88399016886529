import {ChangeEvent, useEffect, useRef, useState} from 'react';
import {useNavigate, useOutletContext} from 'react-router-dom';

import Halfview from '@/components/Halfview';
import Input from '@/components/Input';
import {ROUTES} from '@/constants/routes';
import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import {useHistoryBackAOS} from '@/hooks/useHistoryBackAOS';
import useTiara from '@/hooks/useTiara';
import {IssueOutletContext} from '@/pages/issue/index';
import EmptyList from '@/pages/issue/universitySearch/components/EmptyList';
import UniversityList from '@/pages/issue/universitySearch/components/UniversityList';
import {UniversitiesStatus, useUniversities} from '@/pages/issue/universitySearch/hooks/useUniversities';
import {useHalfviewStore} from '@/store/halfview';

const UniversitySearch = () => {
  const outletContext = useOutletContext<IssueOutletContext>();
  const {trackPage, trackEvent} = useTiara({pageName: TIARA_PAGE_NAME.ISSUE_UNIVERSITY_SEARCH});
  const navigate = useNavigate();
  const {universities, fetchUniversities, status, cancelFetch} = useUniversities();
  const {setOpen} = useHalfviewStore();

  /** 학교 선택이 발생했는지 여부 */
  const isUniversitySelected = useRef<boolean>(false);
  const onSelectUniversity = () => {
    isUniversitySelected.current = true;
  };

  const [input, setInput] = useState('');
  const INPUT_ID = 'searchInp';

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const onClickClear = () => {
    setInput('');
    cancelFetch();
  };

  /**
   * input element에 바로 autofocus를 넣을 경우 스무디와 타이밍 이슈로
   * 화면이 위로 튀는 현상이 있음. 그래서 autofocus 속성을 사용하지 않고,
   * 스무디가 마운트된 이후에 해당 input element에 포커스 잡히도록 개발
   */
  const autoFocusInputElement = () => {
    const inputRef = document.getElementById(INPUT_ID) as HTMLInputElement | null;
    inputRef?.focus();
  };

  /** 하프뷰가 닫힌 경우 */
  const onExited = () => {
    trackEvent({action: TIARA_ACTION.CLOSE_CLICK});
    navigate(ROUTES.ISSUE);
    // 학교 선택하여 닫는 경우 학위 선택으로 바로 이동
    if (isUniversitySelected.current) {
      isUniversitySelected.current = false;
      outletContext.onExitedWithUniversitySelected();
    }
  };

  useHistoryBackAOS(() => setOpen(false));

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchUniversities(input);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [input]);

  useEffect(() => {
    trackPage({action: TIARA_ACTION.ISSUE_UNIVERSITY_SEARCH_VIEW});
  }, []);

  const render = () => {
    if (status === UniversitiesStatus.RESOLVE) {
      return universities.length ? (
        <UniversityList onSelectUniversity={onSelectUniversity} universities={universities} />
      ) : (
        <EmptyList />
      );
    }
    return null;
  };

  return (
    <Halfview.Wrapper classnames="modal_full" onExited={onExited} onEntered={autoFocusInputElement}>
      <Halfview.Header className="header_type2">
        <h4 id="modalTtile" className="tit_header">
          <span role="text">학교 검색</span>
        </h4>
      </Halfview.Header>
      <Halfview.Body>
        <div className="group_search">
          <Input
            input={input}
            type="search"
            htmlFor={INPUT_ID}
            onChange={onChange}
            onClickClear={onClickClear}
            label="학교 이름 검색"
            placeholder="학교 이름을 검색해 주세요."
            showFixedConfirmBtm={false}
          />
          <div className="search_suggest" role="alert">
            {universities.length}개의 검색결과 표시됨
          </div>
        </div>
        {render()}
      </Halfview.Body>
    </Halfview.Wrapper>
  );
};

export default UniversitySearch;
