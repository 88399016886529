import {openExternalWebview} from '@zzng/common/scheme/web';

import MainContent from '@/components/MainContent';
import Space from '@/components/Space';
import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import useTiara from '@/hooks/useTiara';
import ConfirmButtonBottom from '@/pages/error/components/ConfirmButtonBottom';

const InvalidUniversityDegree = () => {
  const {trackEvent} = useTiara({pageName: TIARA_PAGE_NAME.ISSUE_FAIL});

  const onClickCS = () => {
    trackEvent({action: TIARA_ACTION.CUSTOMER_SERVICE_CLICK});
    openExternalWebview('https://cs.kakao.com/requests?service=179&locale=ko', {isCurrentWebviewClose: false});
  };
  return (
    <MainContent className="content-full">
      <div className="inner_g">
        <div className="group_empty">
          <strong className="tit_g type4">
            <span className="item_txt" role="text">
              <span className="txt_tit">
                해당 학교 또는 학위로
                <br /> 발급이 불가합니다
              </span>
            </span>
          </strong>
          <p className="txt_g type4">
            신청 정보 확인 후 다시 시도해 주세요.
            <br /> 문제가 계속된다면
            <Space />
            <a href="#none" onClick={onClickCS} className="link_g txt_blue" target="_blank">
              고객센터
            </a>
            로 문의해주세요.
          </p>
          <span className="icon icon_issuance4"></span>
        </div>
      </div>
      <ConfirmButtonBottom />
    </MainContent>
  );
};

export default InvalidUniversityDegree;
