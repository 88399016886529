import {useEffect, useLayoutEffect} from 'react';
import cn from 'classnames';

import {SCHEME} from '@zzng/common/constants/scheme';
import {executeAppScheme} from '@zzng/common/utils/scheme';

import Halfview from '@/components/Halfview';
import {ROUTES} from '@/constants/routes';
import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import useTiara from '@/hooks/useTiara';
import {PostSignRes} from '@/models/issue';
import AgreementItem from '@/pages/issue/agreement/components/AgreementItem';
import {useAgreementStatusStore} from '@/store/agreementStatus';
import {useHalfviewStore} from '@/store/halfview';
import {useUniversity} from '@/store/university';
import {api} from '@/utils/api';

export interface AgreementItemType {
  ariaLabel: string;
  checked: boolean;
  isRequired: boolean;
  setStatus: (checked: boolean) => void;
  agreementDetailRoute?: string;
  needToHide: boolean;
}

const Agreement = () => {
  const {trackPage, trackEvent} = useTiara({pageName: TIARA_PAGE_NAME.ISSUE_AGREEMENT});
  const {publisher} = useUniversity();
  const {
    needToShow,
    optionalAgreementStatus,
    setOptionalAgreementStatus,
    requiredAgreementStatus,
    setRequiredAgreementStatus
  } = useAgreementStatusStore();
  const {setHalfviewClassname, setOpen} = useHalfviewStore();

  const {needToShowChannel, needToShowMarketing} = needToShow;
  const {isChannelPlus, isMarketingUsage} = optionalAgreementStatus;
  const {requiredKakaoCollectUse, requiredPersonalInfo, requiredPublisher} = requiredAgreementStatus;
  const isRequiredfilled = Object.entries(requiredAgreementStatus).every(([, isAgreed]) => isAgreed);
  const isFulfill =
    isRequiredfilled && (!needToShowChannel || isChannelPlus) && (!needToShowMarketing || isMarketingUsage);

  const onAllCheckClick: AgreementItemType['setStatus'] = (checked) => {
    setRequiredAgreementStatus({
      requiredPublisher: checked,
      requiredPersonalInfo: checked,
      requiredKakaoCollectUse: checked
    });
    setOptionalAgreementStatus({isChannelPlus: checked, isMarketingUsage: checked});
  };

  const onSubmitClick = async () => {
    trackEvent({action: TIARA_ACTION.SIGN_AND_ISSUE_CLICK});

    const {
      data: {txId}
    } = await api<PostSignRes>('post', '/api/sign');
    setOpen(false);
    executeAppScheme(`${SCHEME.SIGN}?tx_id=${txId}`);
  };

  useLayoutEffect(() => {
    setHalfviewClassname('modal_agreement');
  }, []);

  useEffect(() => {
    trackPage({action: TIARA_ACTION.ISSUE_AGREEMENT_VIEW});
  }, []);

  const AGREEMENT_LIST_ITEM: AgreementItemType[] = [
    {
      ariaLabel: '[필수] 카카오 개인정보 수집 및 이용 동의',
      checked: requiredKakaoCollectUse,
      isRequired: true,
      setStatus: (checked) =>
        setRequiredAgreementStatus({...requiredAgreementStatus, requiredKakaoCollectUse: checked}),
      agreementDetailRoute: ROUTES.ISSUE_KAKAO_COLLECT_USE_TERMS,
      needToHide: false
    },
    {
      ariaLabel: '[필수] 카카오 개인정보 제3자 제공 동의',
      checked: requiredPersonalInfo,
      isRequired: true,
      setStatus: (checked) => setRequiredAgreementStatus({...requiredAgreementStatus, requiredPersonalInfo: checked}),
      agreementDetailRoute: ROUTES.ISSUE_KAKAO_TERMS,
      needToHide: false
    },
    {
      ariaLabel: `[필수] ${publisher?.name} 개인정보 제3자 제공 동의`,
      checked: requiredPublisher,
      isRequired: true,
      setStatus: (checked) => setRequiredAgreementStatus({...requiredAgreementStatus, requiredPublisher: checked}),
      agreementDetailRoute: ROUTES.ISSUE_PUBLISHER_TERMS,
      needToHide: false
    },
    {
      ariaLabel: '[선택] 디지털카드 맞춤형 서비스를 위한 개인정보 이용 동의',
      checked: isMarketingUsage,
      isRequired: false,
      setStatus: (checked) => setOptionalAgreementStatus({...optionalAgreementStatus, isMarketingUsage: checked}),
      agreementDetailRoute: ROUTES.ISSUE_MARKETING_USAGE_TERMS,
      needToHide: !needToShowMarketing
    },
    {
      ariaLabel: `[선택] 톡디지털카드 채널의 광고와 마케팅 메시지를 카카오톡으로 받습니다.`,
      checked: isChannelPlus,
      isRequired: false,
      setStatus: (checked) => setOptionalAgreementStatus({...optionalAgreementStatus, isChannelPlus: checked}),
      needToHide: !needToShowChannel
    }
  ];

  return (
    <>
      <Halfview.Header className="header_type2">
        <h3 id="modalTtile" className="tit_header">
          <span role="text">이용약관 동의</span>
        </h3>
      </Halfview.Header>
      <Halfview.Body>
        <div className="group_check">
          <AgreementItem ariaLabel="전체 동의하기" checked={isFulfill} setStatus={onAllCheckClick} idNumber="all" />
          {AGREEMENT_LIST_ITEM.map(
            (item, idx) =>
              !item.needToHide && <AgreementItem key={idx} idNumber={(idx + 1).toString().padStart(2, '0')} {...item} />
          )}
        </div>
      </Halfview.Body>
      <Halfview.Footer>
        <div className="group_btn">
          <button
            type="button"
            className={cn('btn btn_yellow', {disabled: !isRequiredfilled})}
            disabled={!isRequiredfilled}
            onClick={onSubmitClick}
          >
            인증 후 발급하기
          </button>
        </div>
      </Halfview.Footer>
    </>
  );
};

export default Agreement;
