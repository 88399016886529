import {isDev} from '@zzng/common/utils/common';

const getAppkey = () => {
  if (isDev) {
    return '794f3375e60a4ba44d10374ced8f0153';
  }

  return 'af85ceea9bdf0dfb2f558217079e6e4c';
};

const TemplateIdMap = {
  INTRO: {
    dev: 1383,
    prod: 92825
  },
  BENEFIT: {
    dev: 1667,
    prod: 102847
  }
} satisfies Record<
  string,
  {
    dev: number;
    prod: number;
  }
>;

export const sendTMS = (templateKey: keyof typeof TemplateIdMap, templateArgs: Record<string, string> = {}) => {
  if (!window.Kakao?.isInitialized()) {
    window.Kakao?.init(getAppkey());
  }

  window.Kakao?.Share.sendCustom({
    templateId: TemplateIdMap[templateKey][isDev ? 'dev' : 'prod'],
    installTalk: true,
    templateArgs
  });
};
