import {Navigate, Outlet} from 'react-router-dom';

import {isRequiredKakaoTalkVersionForStudent} from '@zzng/common/scheme/digitalcard';
import {isKakaoTalk} from '@zzng/common/utils/agent';
import {isLocal} from '@zzng/common/utils/common';

import {ROUTES} from '@/constants/routes';

const InAppPrivateRoutes = () => {
  if (isLocal) {
    return <Outlet />;
  }

  if (!isKakaoTalk) {
    return <Navigate to={ROUTES.ROOT} />;
  }

  if (!isRequiredKakaoTalkVersionForStudent) {
    return <Navigate to={ROUTES.INVALID_VERSION} replace={true} />;
  }

  return <Outlet />;
};

export default InAppPrivateRoutes;
