import {useCallback, useEffect, useRef, useState} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import axios, {AxiosError, CancelTokenSource} from 'axios';

import {HTTP_STATUS_CODE} from '@zzng/common/constants/httpStatusCode';
import {openDigitalCard} from '@zzng/common/scheme/digitalcard';
import {isTabletOrMobile} from '@zzng/common/utils/agent';

import {OutletPopupType} from '@/components/OutletPopup';
import {PromotionErrorCode} from '@/constants/promotionErrorCode';
import {ROUTES} from '@/constants/routes';
import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import useTiara from '@/hooks/useTiara';
import {EmoticonPromotionError, EmoticonPromotionRes, PromotionProceedingRes} from '@/models/Promotion';
import {api} from '@/utils/api';
import {login} from '@/utils/kakaoLogin';

enum PROMOTION_STATE {
  LOADING = 'loading',
  OPEN = 'open',
  CLOSE = 'close',
  ERROR = 'error'
}

const TIARA_POPUP_MAP = {
  [PROMOTION_STATE.LOADING]: TIARA_ACTION.RETRY_POPUP_VIEW,
  [PROMOTION_STATE.ERROR]: TIARA_ACTION.PAGE_RELOAD_POPUP_VIEW,
  [PROMOTION_STATE.CLOSE]: TIARA_ACTION.EMOTICON_PROMOTION_STOP_POPUP_VIEW,
  [PromotionErrorCode.LOGIN_REQUIRED]: TIARA_ACTION.NOT_LOGIN_POPUP_VIEW,
  [PromotionErrorCode.DUPLICATE_PARTICIPATION]: TIARA_ACTION.ALREADY_REQUEST_EMOTICON_POPUP_VIEW,
  [PromotionErrorCode.UNKNOWN]: TIARA_ACTION.SERVICE_ERROR_POPUP_VIEW,
  [PromotionErrorCode.NO_CARD]: TIARA_ACTION.NEED_STUDENTID_POPUP_VIEW,
  EMOTICON_REQUEST_SUCCESS: TIARA_ACTION.EMOTICON_REQUEST_SUCCESS_POPUP_VIEW
};

export const usePromotion = () => {
  const navigate = useNavigate();
  const {trackEvent, trackPage} = useTiara({pageName: TIARA_PAGE_NAME.SERVICE_INTRO});
  const [promotionState, setPromotionState] = useState<PROMOTION_STATE>();
  const [outletPopup, setOutletPopup] = useState<OutletPopupType | null>(null);
  const apiSource = useRef<CancelTokenSource | null>(null);

  const openPopup = ({title, desc, yellowButton, grayButton}: OutletPopupType) => {
    setOutletPopup({title, desc, yellowButton, grayButton, tiaraPageName: TIARA_PAGE_NAME.SERVICE_INTRO});
    navigate(ROUTES.ROOT_POPUP);
  };

  const alertPromotionState = useCallback(() => {
    switch (promotionState) {
      case PROMOTION_STATE.OPEN:
        return;
      case PROMOTION_STATE.LOADING:
        openPopup({
          title: '서비스 오류 안내',
          desc: '예상하지 못한 오류가 발생했습니다. 잠시 후 다시 시도해 주세요.'
        });
        break;
      case PROMOTION_STATE.CLOSE:
        openPopup({
          title: '이모티콘 지급이 일시적으로 중단되었습니다',
          desc: '현재 이모티콘을 받을 수 없습니다. 잠시 후 다시 시도해 주세요.'
        });
        break;
      case PROMOTION_STATE.ERROR:
      default:
        openPopup({
          title: '서비스 오류 안내',
          desc: '예상하지 못한 오류가 발생했습니다. 페이지를 새로고침 해주세요.',
          yellowButton: {
            value: '새로고침',
            onClick: () => {
              trackEvent({action: TIARA_ACTION.RELOAD_CLICK});
              window.location.reload();
            }
          },
          grayButton: {
            value: '취소',
            onClick: () => {
              trackEvent({action: TIARA_ACTION.CANCEL_CLICK});
              navigate(-1);
            }
          }
        });
    }
    trackPage({action: TIARA_POPUP_MAP[promotionState || PROMOTION_STATE.ERROR]});
  }, [promotionState]);

  const isPromotionOpen = useCallback(() => promotionState === PROMOTION_STATE.OPEN, [promotionState]);

  const emoticonRequestErrorHandler = (error: AxiosError<EmoticonPromotionError>) => {
    switch (error.response?.data.code) {
      case PromotionErrorCode.LOGIN_REQUIRED:
        openPopup({
          title: '카카오계정으로 로그인해 주세요',
          desc: '이모티콘 받기는 카카오계정으로 로그인이 필요합니다.',
          yellowButton: {
            value: '로그인하기',
            onClick: () => {
              trackEvent({action: TIARA_ACTION.LOGIN_CLICK});
              login({redirectURL: STUDENT_ORIGIN});
            }
          },
          grayButton: {
            value: '취소',
            onClick: () => {
              trackEvent({action: TIARA_ACTION.CANCEL_CLICK});
              navigate(-1);
            }
          }
        });
        break;
      case PromotionErrorCode.NO_CARD:
        openPopup({
          title: '톡학생증을 받아주세요',
          desc: '톡학생증을 발급 받은 후 이모티콘 받기를 다시 시도해 주세요.',
          grayButton: {
            value: '확인',
            onClick: () => {
              trackEvent({action: TIARA_ACTION.CONFIRM_CLICK});

              if (!isTabletOrMobile) {
                navigate(ROUTES.ONLY_MOBILE, {replace: true});
                return;
              }

              navigate(-1);

              // [IOS] 뒤로가기와 웹뷰 실행을 같이 하면 뒤로가기가 안 되는 현상이 있어서 비동기로 웹뷰를 연다.
              setTimeout(() => {
                openDigitalCard(`${STUDENT_ORIGIN}${ROUTES.ISSUE}`);
              }, 0);
            }
          }
        });
        break;
      case PromotionErrorCode.DUPLICATE_PARTICIPATION:
        openPopup({
          title: '이미 톡학생증과 이모티콘을 받았습니다',
          desc: '이모티콘 지급은 카카오톡 톡디지털카드 채널에서 이전에 발송한 메세지를 확인해 주세요.'
        });
        break;
      case PromotionErrorCode.UNKNOWN:
      default:
        openPopup({
          title: '서비스 오류 안내',
          desc: '예상하지 못한 오류가 발생했습니다. 잠시 후 다시 시도해 주세요.'
        });
    }
    trackPage({action: TIARA_POPUP_MAP[error.response?.data.code || PromotionErrorCode.UNKNOWN]});
  };

  const protectDuplicatedRequest = () => {
    if (apiSource.current) {
      apiSource.current.cancel();
    }

    apiSource.current = axios.CancelToken.source();
  };

  const requestEmoticon = async () => {
    if (!isPromotionOpen()) {
      alertPromotionState();
      return;
    }

    protectDuplicatedRequest();

    try {
      const {status} = await api<EmoticonPromotionRes>(
        'post',
        '/api/promotions/emoticon',
        {},
        {
          cancelToken: apiSource.current?.token
        }
      );
      if (status === HTTP_STATUS_CODE.OK) {
        openPopup({
          title: '이모티콘이 지급되었습니다!',
          desc: '카카오톡 톡디지털카드 채널에서 메시지를 확인해 주세요.'
        });
        trackPage({action: TIARA_POPUP_MAP.EMOTICON_REQUEST_SUCCESS});
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        if (axios.isCancel(e)) return;
        emoticonRequestErrorHandler(e);
      } else {
        openPopup({
          title: '서비스 오류 안내',
          desc: '예상하지 못한 오류가 발생했습니다. 잠시 후 다시 시도해 주세요.'
        });
        trackPage({action: TIARA_POPUP_MAP[PromotionErrorCode.UNKNOWN]});
      }
    }
  };

  useEffect(() => {
    const requestPromotionState = async () => {
      setPromotionState(PROMOTION_STATE.LOADING);

      try {
        const {
          data: {result}
        } = await api<PromotionProceedingRes>('get', '/api/promotions/proceeding?type=emoticon');

        setPromotionState(result ? PROMOTION_STATE.OPEN : PROMOTION_STATE.CLOSE);
      } catch (e) {
        setPromotionState(PROMOTION_STATE.ERROR);
      }
    };

    requestPromotionState();
  }, []);

  return {requestEmoticon, outletPopupContext: <Outlet key="outlet-popup" context={[outletPopup]} />} as const;
};
