import {useEffect} from 'react';

import {isRequiredKakaoTalkVersionForDigitalCard} from '@zzng/common/scheme/digitalcard';
import {closeWebView} from '@zzng/common/scheme/web';
import {goKakaotalkAppStore} from '@zzng/common/utils/scheme';

import Header from '@/components/Header';
import Layout from '@/components/Layout';
import MainContent from '@/components/MainContent';
import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import useTiara from '@/hooks/useTiara';

const InvalidVersion = () => {
  const {trackPage, trackEvent} = useTiara({pageName: TIARA_PAGE_NAME.ERROR});

  const onClickClose = () => {
    trackEvent({action: TIARA_ACTION.CLOSE_CLICK});
    closeWebView();
  };

  const onClick = () => {
    trackEvent({action: TIARA_ACTION.UPDATE_VERSION_CLICK});
    goKakaotalkAppStore();
  };

  useEffect(() => {
    trackPage({action: TIARA_ACTION.ERROR_INVALID_VERSION_VIEW});
  }, []);

  return (
    <Layout className="container-bridge">
      {isRequiredKakaoTalkVersionForDigitalCard && <Header title="톡학생증 발급 오류" onClickClose={onClickClose} />}
      <MainContent className="content-full">
        <div className="inner_g">
          <div className="group_empty">
            <span className="emoticon type3"></span>
            <strong className="tit_g">
              <span className="item_txt" role="text">
                <span className="txt_tit">최신버전으로 업데이트해 주세요</span>
              </span>
            </strong>
            <p className="txt_g">카카오톡의 최신버전 업데이트가 필요한 기능입니다.</p>
            <button type="button" className="btn btn_yellow" onClick={onClick}>
              최신버전 업데이트
            </button>
          </div>
        </div>
      </MainContent>
    </Layout>
  );
};

export default InvalidVersion;
