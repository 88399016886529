import {useEffect, useRef, useState} from 'react';
import axios, {CancelTokenSource} from 'axios';

import {UniversitiesRes} from '@/models/Universities';
import {api} from '@/utils/api';

export enum UniversitiesStatus {
  INITIAL = 'INITIAL',
  PENDING = 'PENDING',
  RESOLVE = 'RESOLVE'
}

export const useUniversities = () => {
  const source = useRef<CancelTokenSource | null>(null);
  const [status, setStatus] = useState<UniversitiesStatus>(UniversitiesStatus.INITIAL);
  const [universities, setUniversities] = useState<UniversitiesRes>([]);

  const cancelFetch = () => {
    source.current?.cancel();
    source.current = null;
  };

  const fetch = async (query: string) => {
    if (!source.current) {
      source.current = axios.CancelToken.source();
    }

    setStatus(UniversitiesStatus.PENDING);

    try {
      const res = await api<UniversitiesRes>(
        'get',
        `/api/universities`,
        {query, active: true},
        {cancelToken: source.current.token}
      );

      setStatus(UniversitiesStatus.RESOLVE);

      if (res.status === 200) {
        // NOTE: 매트릭스 에러가 여러번 발생하여 방어 코드 추가
        // https://kko.to/5ltEy9uoSR
        setUniversities(Array.isArray(res.data) ? res.data : []);
      }
    } catch (e) {
      if (axios.isCancel(e)) return;

      throw e;
    }
  };

  /**
   * 검색어로 대학 조회
   * @param query 검색어
   */
  const fetchUniversities = (query: string) => {
    if (!query) {
      setUniversities([]);
      setStatus(UniversitiesStatus.INITIAL);
      return;
    }

    return fetch(query);
  };

  /** 모든 대학 조회 */
  const fetchAllUniversities = () => fetch('');

  useEffect(() => {
    source.current = axios.CancelToken.source();

    return () => {
      source.current?.cancel();
    };
  }, []);

  return {universities, fetchUniversities, fetchAllUniversities, status, cancelFetch};
};
