import {create} from 'zustand';

type HalfviewClassname = 'modal_half' | 'modal_full' | 'modal_agreement' | 'modal_full modal_policy';
type HalfviewStore = {
  open: boolean;
  halfviewClassname: HalfviewClassname;
  setOpen: (open: boolean) => void;
  setHalfviewClassname: (halfviewClassname: HalfviewClassname) => void;
};

export const useHalfviewStore = create<HalfviewStore>()((set) => ({
  open: true,
  halfviewClassname: 'modal_half',
  setOpen: (open: boolean) => {
    set((prev) => ({...prev, open}));
  },
  setHalfviewClassname: (halfviewClassname: HalfviewClassname) => {
    set((prev) => ({...prev, halfviewClassname}));
  }
}));
