import {memo, MouseEvent} from 'react';

import {getOsVersion, isAndroid} from '@zzng/common/utils/agent';

import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import useTiara from '@/hooks/useTiara';
import copy from '@/utils/copy';
import {sendTMS} from '@/utils/developers';

type Props = {
  openToast: () => void;
};

const WrapShare = ({openToast}: Props) => {
  const {trackEvent} = useTiara({pageName: TIARA_PAGE_NAME.SERVICE_INTRO});

  const onShareClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    trackEvent({action: TIARA_ACTION.SHARE_KAKAOTALK_CLICK});
    sendTMS('INTRO');
  };

  const onShareUrlClick = async (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    trackEvent({action: TIARA_ACTION.SHARE_URL_CLICK});
    const {major} = await getOsVersion();
    await copy(STUDENT_ORIGIN);

    // 안드로이드>=13부터는 내장 토스트를 사용하기 때문에 토스트를 노출하지 않는다
    // https://developer.android.com/about/versions/13/features/copy-paste?hl=ko
    if (isAndroid && major >= 13) return;

    openToast();
  };

  return (
    <div className="wrap_share">
      <div className="inner_wrap">
        <span className="ico_intro ico_chunsik" />
        <h3 className="screen_out">공유하기</h3>
        <span role="text" className="ico_intro ico_share">
          친구에게 알려주고 싶다면 <br />
          지금 공유 버튼 꾹!
        </span>
        <div className="group_btn">
          <a href="#none" className="btn_intro btn_share" onClick={onShareClick}>
            <div className="ico_intro ico_btn3">공유하기</div>
          </a>
          <a href="#none" className="btn_intro btn_copy" onClick={onShareUrlClick}>
            <div className="ico_intro ico_btn4">복사하기</div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default memo(WrapShare);
