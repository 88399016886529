import {create} from 'zustand';

type CardIdStore = {
  cardId: string;
  setCardId: (cardId: string) => void;
};

export const useCardIdStore = create<CardIdStore>()((set) => ({
  cardId: '',
  setCardId: (cardId: string) => {
    set((prev) => ({...prev, cardId}));
  }
}));
