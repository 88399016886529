import {closeWebView} from '@zzng/common/scheme/web';

import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import useTiara from '@/hooks/useTiara';

const ConfirmButtonBottom = () => {
  const {trackEvent} = useTiara({pageName: TIARA_PAGE_NAME.ISSUE_FAIL});

  const onClick = () => {
    trackEvent({action: TIARA_ACTION.CONFIRM_CLICK});
    closeWebView();
  };

  return (
    <div className="fixed_btm">
      <div className="group_btn">
        <button type="button" className="btn btn_yellow" onClick={onClick}>
          확인
        </button>
      </div>
    </div>
  );
};

export default ConfirmButtonBottom;
