import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {addPaddingOnGroupSearch} from '@zzng/common/markup/addPaddingOnGroupEmpty';

import {ROUTES} from '@/constants/routes';
import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import useTiara from '@/hooks/useTiara';

const EmptyList = () => {
  const {trackEvent} = useTiara({pageName: TIARA_PAGE_NAME.ISSUE_UNIVERSITY_SEARCH});
  const navigate = useNavigate();

  const onClick = () => {
    trackEvent({action: TIARA_ACTION.ISSUABLE_UNIVERSITY_VIEW_CLICK});
    navigate(ROUTES.UNIVERSITY_LIST);
  };

  useEffect(addPaddingOnGroupSearch, []);

  return (
    <div className="group_empty type2">
      <p className="txt_g type2">검색 결과가 없습니다.</p>
      <button type="button" className="btn btn_gray type2" onClick={onClick}>
        발급 가능 학교 보기
      </button>
    </div>
  );
};

export default EmptyList;
