import {useEffect} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';

import Header, {HEADER_BUTTON_TYPE} from '@/components/Header';
import Layout from '@/components/Layout';
import MainContent from '@/components/MainContent';
import Space from '@/components/Space';
import {ROUTES} from '@/constants/routes';
import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import {useHistoryBackAOS} from '@/hooks/useHistoryBackAOS';
import useTiara from '@/hooks/useTiara';
import {PopupType, usePopupStore} from '@/store/popup';
import {useUniversity} from '@/store/university';
import {useUserStore} from '@/store/user';

const Confirm = () => {
  const {trackPage, trackEvent} = useTiara({pageName: TIARA_PAGE_NAME.ISSUE_CONFIRM});
  const {name: univName, selectedDegree, studentId} = useUniversity();
  const {name, birthDate} = useUserStore();
  const {isOpen, togglePopup, setConfirmBtnClick, openPopup} = usePopupStore();
  const birthDateWithDot = (date = 0) => date.toString().replace(/(\d{4})(\d{2})(\d{2})/g, '$1.$2.$3');
  const navigate = useNavigate();

  useHistoryBackAOS(() => {
    if (!isOpen) {
      trackEvent({action: TIARA_ACTION.CLOSE_CLICK});
    }
    togglePopup(PopupType.STOP);
  }, [isOpen]);

  const onClickOffer = () => {
    trackEvent({action: TIARA_ACTION.ISSUE_OFFER_CLICK});
    navigate(ROUTES.ISSUE_PROGRESS);
  };

  const onClickBack = () => {
    trackEvent({action: TIARA_ACTION.BACK_CLICK});
    openPopup(PopupType.STOP);
  };

  const onClickCertificateIssue = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    trackEvent({action: TIARA_ACTION.ISSUE_CONFIRM_CERTIFICATE_ISSUE_CLICK});
    navigate(ROUTES.ISSUE_CERTIFICATE_ISSUE_AGREEMENT);
  };

  useEffect(() => {
    trackPage({action: TIARA_ACTION.ISSUE_CONFIRM_VIEW});
    setConfirmBtnClick(() => navigate(ROUTES.ISSUE));
  }, []);

  return (
    <>
      <Layout className="container-viewr">
        <Header title="톡학생증" closeType={HEADER_BUTTON_TYPE.BACK} onClickBack={onClickBack} />
        <MainContent>
          <div className="inner_g">
            <div className="group_tit">
              <h2 className="tit_g">
                <span className="item_txt item_emph" role="text">
                  <span className="txt_tit">아래 정보를 다시 한 번</span>
                  <span className="txt_tit">확인해 주세요.</span>
                </span>
              </h2>
            </div>
            <div className="group_box">
              <dl className="list_def" role="text">
                <dt>이름</dt>
                <dd>{name}</dd>
              </dl>
              <dl className="list_def" role="text">
                <dt>생년월일</dt>
                <dd>{birthDateWithDot(birthDate)}</dd>
              </dl>
              <dl className="list_def" role="text">
                <dt>학교</dt>
                <dd>{univName}</dd>
              </dl>
              <dl className="list_def" role="text">
                <dt>학위</dt>
                <dd>{selectedDegree?.name}</dd>
              </dl>
              <dl className="list_def" role="text">
                <dt>학번</dt>
                <dd>{studentId}</dd>
              </dl>
            </div>
          </div>
          <div className="fixed_btm type2">
            <div className="item_confirm item_g type2">
              <span className="txt_confirm">
                톡학생증 발급에 필요한
                <Space />
                <a className="link_g" onClick={onClickCertificateIssue}>
                  증명서
                </a>
                가 발급됩니다.
              </span>
            </div>
            <div className="group_btn type2">
              <button type="button" className="btn btn_yellow" onClick={onClickOffer}>
                발급 요청하기
              </button>
            </div>
          </div>
        </MainContent>
      </Layout>
      <Outlet />
    </>
  );
};

export default Confirm;
