import MainContent from '@/components/MainContent';
import ConfirmButtonBottom from '@/pages/error/components/ConfirmButtonBottom';
import {useUniversity} from '@/store/university';

const InvalidCredentialCategory = () => {
  const {publisher} = useUniversity();

  return (
    <MainContent className="content-full">
      <div className="inner_g">
        <div className="group_empty">
          <strong className="tit_g type4">
            <span className="item_txt" role="text">
              <span className="txt_tit">
                수료 또는 제적인 상태는
                <br /> 발급이 불가합니다
              </span>
            </span>
          </strong>
          <p className="txt_g type4">
            학적 상태 정정이 필요하다면 해당 대학교 또는
            <br /> {publisher?.name}으로 문의해 주세요.
          </p>
          <span className="icon icon_issuance4"></span>
        </div>
      </div>
      <ConfirmButtonBottom />
    </MainContent>
  );
};

export default InvalidCredentialCategory;
