import {useEffect} from 'react';
import {Navigate} from 'react-router-dom';

import {isMobile, isTablet} from '@zzng/common/utils/agent';

import Header from '@/components/Header';
import Layout from '@/components/Layout';
import MainContent from '@/components/MainContent';
import Space from '@/components/Space';
import {ROUTES} from '@/constants/routes';
import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import useTiara from '@/hooks/useTiara';

const OnlyMobile = () => {
  const {trackPage} = useTiara({pageName: TIARA_PAGE_NAME.ERROR});

  useEffect(() => {
    trackPage({
      action: TIARA_ACTION.ONLY_MOBILE
    });
  }, []);

  // redirect /only-mobile to / on mobile or tablet
  if (isMobile || isTablet) {
    return <Navigate to={ROUTES.ROOT} replace />;
  }

  return (
    <Layout className="container-bridge-pc">
      <Header title="톡학생증 이용안내" />
      <MainContent className="content-full">
        <div className="group_cont">
          <strong className="tit_cont">
            <em className="emph_tit">톡학생증 서비스</em>는<br /> <em className="emph_tit">모바일</em>에서 이용해주세요.
          </strong>
          <p className="txt_cont">
            <span className="img_qr">
              <span className="screen_out">모바일 톡학생증 서비스 바로가기 QR코드 이미지, </span>
            </span>
            <em className="emph_txt">QR코드</em>를 스캔하거나, 카카오톡 실행 후
            <Space />
            <em className="emph_txt">더보기&gt;지갑</em>에서
            <br /> <em className="emph_txt">톡학생증 서비스</em>를 이용해 보세요.
          </p>
        </div>
        <div className="group_visual">
          <div className="visual_mobile mobile_bk"></div>
          <div className="visual_mobile mobile_wh"></div>
        </div>
      </MainContent>
    </Layout>
  );
};

export default OnlyMobile;
