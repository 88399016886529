import {useEffect} from 'react';

import {isRequiredKakaoTalkVersionForStudent} from '@zzng/common/scheme/digitalcard';
import {closeWebView} from '@zzng/common/scheme/web';

import Header, {HEADER_BUTTON_TYPE} from '@/components/Header';
import Layout from '@/components/Layout';
import MainContent from '@/components/MainContent';
import Space from '@/components/Space';
import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import {useHistoryBackAOS} from '@/hooks/useHistoryBackAOS';
import {useLayoutClass} from '@/hooks/useLayoutClass';
import useTiara from '@/hooks/useTiara';
import List from '@/pages/universityList/components/List';

// 톡학생증 발급 가능 학교 목록 페이지
const UniversityList = () => {
  const {trackPage, trackEvent} = useTiara({pageName: TIARA_PAGE_NAME.UNIVERSITY_LIST});

  // 해당 페이지로 직진입한 경우 닫기 버튼 노출, 그 외에는 뒤로가기 노출
  const closeType: HEADER_BUTTON_TYPE = history.length > 1 ? HEADER_BUTTON_TYPE.BACK : HEADER_BUTTON_TYPE.CLOSE;

  const onClickBack = () => {
    trackEvent({action: TIARA_ACTION.CLOSE_CLICK});
    history.back();
  };

  const onClickClose = () => {
    trackEvent({action: TIARA_ACTION.CLOSE_CLICK});
    closeWebView();
  };

  useHistoryBackAOS(closeType === HEADER_BUTTON_TYPE.BACK ? onClickBack : onClickClose);

  useEffect(() => {
    trackPage({action: TIARA_ACTION.UNIVERSITY_LIST_VIEW});
  }, []);

  useLayoutClass();

  return (
    <Layout className={isRequiredKakaoTalkVersionForStudent ? '' : 'type_noheader'}>
      {isRequiredKakaoTalkVersionForStudent && (
        <Header title="톡학생증" closeType={closeType} onClickClose={onClickClose} />
      )}
      <MainContent>
        <div className="wrap_comm wrap_school">
          <img
            src="https://t1.kakaocdn.net/zzng_static/images/20231025_180610/idcard/img_logo.png"
            alt=""
            className="img_logo"
            width="88"
            height="27"
          />
          <div className="school_list">
            <div className="inner_g">
              <h2 className="tit_g type4">
                <span className="txt_tit">톡학생증 발급 가능 학교</span>
              </h2>
              <p className="txt_g type2">
                카카오와 제휴된 증명서 발급 대행기관(아이서티, 아이앤텍, 한국정보인증)에서 전자증명서 서비스 중인 국내
                대학교 또는 대학원만 톡학생증 발급이 가능합니다.
                <br />※ 학교와 증명서 발급 대행기관의 상황에 따라 발급 가능 학교가 유동적으로 변경될 수 있습니다.
              </p>
              <List />
            </div>
          </div>
          <small className="txt_copy">
            Copyright ©
            <Space />
            <a href="#none" className="link_kakao">
              Kakao Corp.
            </a>
            <Space />
            All rights reserved.
          </small>
        </div>
      </MainContent>
    </Layout>
  );
};

export default UniversityList;
