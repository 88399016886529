import {Fragment} from 'react';

import {HANGUEL_FIRST_CONSONANTS, matchHangeulFirstConsonant} from '@zzng/common/utils/string';

import Space from '@/components/Space';
import useAllUniversities from '@/pages/universityList/hooks/useAllUniversities';
import {PopupType, usePopupStore} from '@/store/popup';

interface NameListProps {
  names: string[];
}

const NameList = ({names}: NameListProps) => (
  <>
    {names.sort().map((name, i) => (
      <Fragment key={i}>
        {name}
        <Space />
        <span className="ico_dot" />
        <Space />
      </Fragment>
    ))}
  </>
);

const List = () => {
  const {openPopup} = usePopupStore();

  const openInfoPopup = () => openPopup(PopupType.MAINTENANCE_LIST);

  const {universities} = useAllUniversities();

  /** 점검 중인 대학명 목록 */
  const inMaintenanceUniversityNames = universities.filter((v) => v.maintenance).map((v) => v.name);
  /** 서비스 중인 대학명 목록 */
  const activeUniversityNames = universities.filter((v) => !v.maintenance && v.active).map((v) => v.name);
  /** 서비스 중인 대학명(알파벳으로 시작하는 것만) 목록 */
  const activeUniversityNamesByAlphabet = activeUniversityNames.filter((name) => /^[a-zA-Z]/.test(name));

  return (
    <dl className="list_school">
      {/* NOTE: 서비스 점검 학교가 없는 경우 항목 미노출 */}
      {inMaintenanceUniversityNames.length > 0 && (
        <>
          <dt>
            [서비스 점검 학교]
            <Space />
            <button type="button" className="btn_info" onClick={openInfoPopup}>
              <span className="icon">서비스 점검 학교 안내 버튼</span>
            </button>
          </dt>
          <dd>
            <NameList names={inMaintenanceUniversityNames} />
          </dd>
        </>
      )}
      {/* ㄱ-ㅎ 학교 목록 */}
      {/* NOTE: 자음순 및 알파벳 순으로 제공하되, 해당하는 학교가 없는 경우 항목 미노출 */}
      {HANGUEL_FIRST_CONSONANTS.map((firstConsonant) => {
        const universitiesByFirstConsonant = activeUniversityNames.filter((name) =>
          matchHangeulFirstConsonant(firstConsonant, name)
        );
        return universitiesByFirstConsonant.length ? (
          <Fragment key={firstConsonant}>
            <dt>[{firstConsonant}]</dt>
            <dd>
              <NameList names={universitiesByFirstConsonant} />
            </dd>
          </Fragment>
        ) : null;
      })}
      {/* a-z 학교 목록 */}
      {activeUniversityNames.length > 0 && (
        <>
          <dt>[a~z]</dt>
          <dd>
            <NameList names={activeUniversityNamesByAlphabet} />
          </dd>
        </>
      )}
    </dl>
  );
};

export default List;
