import {create} from 'zustand';
import {createJSONStorage, persist} from 'zustand/middleware';

type presentStore = {
  pTxId: string;
  presentationUrl: string;
  setPTxId: (pTxId: string) => void;
  setPresentationUrl: (presentationUrl: string) => void;
};

export const usePresentStore = create<presentStore>()(
  persist(
    (set) => ({
      pTxId: '',
      setPTxId: (pTxId: string) => {
        set((prev) => ({...prev, pTxId}));
      },
      presentationUrl: '',
      setPresentationUrl: (presentationUrl: string) => {
        set((prev) => ({...prev, presentationUrl}));
      }
    }),
    {
      name: 'studentid_present',
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        pTxId: state.pTxId
      })
    }
  )
);
